import React from 'react';
import { Container } from 'ui/component/container';
import styles from './style.css';

export const RubyContent = () => (
    <div className={styles.popout}>
        <div className={styles.popoutBody}>
            <Container id="ruby-loyalty-rewards-detail" />
        </div>
    </div>
);
