import React from 'react';
import { injectProps } from '@silkpwa/redux';
import { ApolloProvider } from '@apollo/client';
import { ProductDetailsPage } from './product-details-page';
import { WriteReviewPage } from './write-review-page';
import { GraphQlClient } from '../../../graphql/base';

const injectWindow = injectProps('window');

const isReviews = window => (
    window.location.hash &&
    window.location.hash.indexOf('#write-review') > -1
);

const ProductDetailsImpl = ({ product, window }) => (
    <div>
        <ApolloProvider client={GraphQlClient}>
            {isReviews(window) && (
            <WriteReviewPage product={product} />
        )}
            {!isReviews(window) && (
            <ProductDetailsPage product={product} />
        )}
        </ApolloProvider>
    </div>
);

export const ProductDetails = injectWindow(ProductDetailsImpl);
