import React, { useEffect } from 'react';
import { useContainer } from '@silkpwa/redux';
import { selectMatch } from 'ui/util/product-images/select-match';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import ReviewsWrite from 'ui/component/native-reviews/write';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

const prSubmitButton = 'div.pr-submit > div > div.pr-pull-left.pr-btn.pr-btn-default.pr-btn-review.pr-accessible-btn';

const getImage = (images) => {
    const best = selectMatch(images, ['flat']);
    if (best.type === 'match') {
        return best.thumb;
    }
    if (images.length > 0) {
        return images[0].thumb;
    }
    return '';
};

export const WriteReviewPage = ({ product }) => {
    const t = usePhraseTranslater();
    const scrolling = useContainer<any>('scrolling');
    const reviewsConfig = useConfig();

    useEffect(() => {
        setTimeout(() => {
            scrolling.scrollWindowTo({ top: 0 });
        });
    }, []);

    if (reviewsConfig.extension_attributes?.cw_reviews_enabled) {
        return (
            <div className={styles.writeReviewPage}>
                <div className={styles.header}>
                    <a className={styles.redirectImg} href={product.url}>
                        <img src={getImage(product.images)} alt={product.name} />
                    </a>
                    <div>
                        <h3>{t('Write a Review')}</h3>
                        <h5><a className={styles.redirect} href={product.url}>{product.name}</a></h5>
                    </div>
                </div>
                <div
                    onClick={(ev) => {
                        if (!(ev.target as any).matches(prSubmitButton)) return;
                        scrolling.scrollWindowTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }}
                    role="presentation"
                >
                    <ReviewsWrite product={product} />
                </div>
            </div>
        );
    }

    return (
        <div className={styles.writeReviewPage}>
            <div className={styles.header}>
                <img src={getImage(product.images)} alt={product.name} />
                <div>
                    <h3>{t('Write a Review')}</h3>
                    <h5>{product.name}</h5>
                </div>
            </div>
            <div
                onClick={(ev) => {
                    if (!(ev.target as any).matches(prSubmitButton)) return;
                    scrolling.scrollWindowTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                }}
                role="presentation"
            />
        </div>
    );
};
