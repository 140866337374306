import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'ui/component/native-reviews/display/display/modal-image';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useMutation } from '@apollo/client';
import { FLAG_REVIEW_MUTATION } from 'graphql/review/mutations/flag-review';
import { classes } from '@silkpwa/module/util/classes';
import DOMPurify from 'dompurify';
import { ReviewIssues } from 'ui/component/native-reviews/display/display/flag-issue-options';
import ReCAPTCHA from 'react-google-recaptcha';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { FlaggingFormGroup } from 'ui/component/native-reviews/display/display/flagging-form-group';
import { ShowLoader } from 'ui/component/show-loader';
import { useContainerHook } from '@silkpwa/redux';
import style from 'ui/component/native-reviews/display/style.css';

export const FlagReviewPopup = ({
    isOpen, onClose, reviewId, onSuccess,
}) => {
    const t = usePhraseTranslater();
    const enqueueNotification = useContainerHook<() => any>('useEnqueueNotification');
    const ecommerceConfig = useConfig();
    const [issue, setIssue] = useState(ReviewIssues.PROFANITY);
    const [email, setEmail] = useState('');
    const [comments, setComments] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [commentError, setCommentError] = useState(false);
    const [commentErrorMessage, setCommentErrorMessage] = useState('');
    const [charactersLeft, setCharactersLeft] = useState(1024);
    const [isLoading, setIsLoading] = useState(false);
    const [createFlagReview] = useMutation(FLAG_REVIEW_MUTATION);
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    useEffect(() => {
        setCharactersLeft(1024 - comments.length);
        if (comments.length > 1024) {
            setCommentError(true);
            setCommentErrorMessage(t('Comments cannot exceed 1024 characters'));
        } else {
            setCommentError(false);
            setCommentErrorMessage('');
        }
    }, [comments]);

    const handleClose = () => {
        if (isLoading) return;

        setIssue(ReviewIssues.PROFANITY);
        setEmail('');
        setComments('');
        setEmailError(false);
        setEmailErrorMessage('');
        setCommentError(false);
        setCommentErrorMessage('');
        onClose();
    };

    const sanitizeComment = comment => DOMPurify.sanitize(comment);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!email) {
            setEmailError(true);
            setEmailErrorMessage(t('Email address is required'));
            setIsLoading(false);
            return;
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            setEmailError(true);
            setEmailErrorMessage(t('Please enter a valid email address'));
            setIsLoading(false);
            return;
        }

        if (comments.length > 1024) {
            setCommentError(true);
            setCommentErrorMessage(t('Comments cannot exceed 1024 characters'));
            setIsLoading(false);
            return;
        }

        let recaptchaToken: string | null = '';
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
            recaptchaToken = await recaptchaRef.current.executeAsync();
        }

        setEmailError(false);
        const sanitizedComment = sanitizeComment(comments);

        const response = await createFlagReview({
            variables: {
                input: {
                    review_id: reviewId,
                    contact_email: email,
                    issue_type: issue,
                    comment: sanitizedComment,
                },
                context: {
                    headers: {
                        'X-ReCaptcha': recaptchaToken,
                    },
                },
            },
            onError: () => {
                enqueueNotification({
                    type: 'primary',
                    message: t('Something went wrong please try again.'),
                    time: 5000,
                });
                setIsLoading(false);
            },
        });

        const { data } = response;
        if (data?.createFlagReview.status === 0) {
            onSuccess();
            handleClose();
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} modalContent={style.flagReviewPopup}>
            {isLoading && (
                <div className={style.submitLoader}>
                    <ShowLoader message={t('Please wait...')} />
                </div>
            )}
            <form className={style.flagForm} onSubmit={handleSubmit}>
                <b>
                    {t('What\'s the issue')}
                    <abbr aria-hidden="true">*</abbr>
                </b>
                <div className={style.flaggingRadioGroup}>
                    <FlaggingFormGroup issue={issue} setIssue={setIssue} t={t} />
                    <div className={style.flagReviewFieldset}>
                        <label
                            htmlFor="pr-email-field"
                            className={classes(style.flagReviewFieldsetLabel,
                                { [style.flagReviewFieldsetLabelError]: emailError })}
                        >
                            <b>{t('Email Address')}</b>
                            <abbr aria-hidden="true">*</abbr>
                        </label>
                        <input
                            id="pr-email-field"
                            className={classes(style.flagEmailField, { [style.flagEmailFieldError]: emailError })}
                            type="email"
                            value={email}
                            aria-required="true"
                            autoComplete="email"
                            onChange={e => setEmail(e.target.value)}
                        />
                        {emailError && (
                            <span className={style.errorMessage}>
                                {emailErrorMessage}
                            </span>
                        )}
                    </div>

                    <div className={style.flagReviewFieldset}>
                        <label
                            className={
                            classes(style.flagReviewFieldsetLabel,
                                { [style.flagReviewFieldsetLabelError]: commentError })}
                        >
                            {t('Comments')}
                            <textarea
                                maxLength={1024}
                                className={style.flagComments}
                                value={comments}
                                onChange={e => setComments(e.target.value)}
                            />
                            {commentError && (
                                <span className={style.errorMessage}>
                                    {commentErrorMessage}
                                </span>
                            )}
                            {charactersLeft <= 300 && (
                                <div id="comments-middle" className={style.reviewCommentsStart}>
                                    {t(`Maximum characters left: ${charactersLeft}`)}
                                </div>
                            )}
                        </label>
                    </div>
                    <div className={style.captcha}>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={ecommerceConfig.extension_attributes?.recaptcha_public_key || ''}
                            size="invisible"
                        />
                    </div>
                    <div className={style.flagReviewActions}>
                        <button type="submit" className={style.flagButton} disabled={isLoading}>
                            {t('Flag')}
                        </button>
                        <button type="button" onClick={handleClose} className={style.cancelButton} disabled={isLoading}>
                            {t('Cancel')}
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};
