import * as React from 'react';
import { IPropsIcons } from 'ui/util/type-helper';

const ThumpsUpIcon: React.FC<IPropsIcons> = ({
    width = 30,
    height = 30,
    color = '#000000',
    fill = '#707070',
}) => (
    <span>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            aria-hidden="true"
            viewBox="0 0 216 146"
            width={width}
            height={height}
            color={color}
            stroke={color}
        >
            <g fill={fill}>
                <path
                    d="M168.7 79.9c3-3.4 4.5-7.5 4.5-12.1 0-4.2-1.6-7.9-4.6-11-3.1-3.1-6.8-4.6-11-4.6H135c.2-.8.4-1.4.7-2s.5-1.1.9-1.8.7-1.1.8-1.5c1-1.8 1.7-3.4 2.2-4.6.5-1.2 1-2.8 1.5-4.9.5-2.1.8-4.1.8-6.2V28c0-.8-.2-2-.4-3.7-.2-1.6-.6-3-1-4.1-.4-1.1-1.1-2.3-2-3.7-.9-1.4-2-2.5-3.3-3.3s-2.9-1.5-4.9-2.1c-2-.6-4.2-.9-6.7-.9-1.4 0-2.6.5-3.7 1.5-1.1 1.1-2 2.4-2.8 4.1-.8 1.6-1.3 3-1.6 4.2-.3 1.2-.6 2.9-1 5-.5 2.3-.9 3.9-1.1 4.9-.2 1-.7 2.3-1.4 4-.7 1.6-1.5 2.9-2.5 3.9-1.8 1.8-4.5 5.1-8.2 9.8-2.7 3.5-5.4 6.8-8.2 9.9-2.8 3.1-4.9 4.7-6.2 4.8-1.4.1-2.5.7-3.5 1.7s-1.5 2.2-1.5 3.5v52.2c0 1.4.5 2.6 1.5 3.6s2.3 1.5 3.7 1.6c1.9.1 6.2 1.2 12.9 3.6 4.2 1.4 7.5 2.5 9.8 3.2 2.4.7 5.7 1.5 9.9 2.4 4.2.8 8.1 1.3 11.7 1.3h10.5c7.2-.1 12.6-2.2 16.1-6.4 3.1-3.7 4.5-8.7 4-14.7 2.1-2 3.6-4.6 4.4-7.7.9-3.3.9-6.5 0-9.5 2.5-3.3 3.7-7 3.5-11.2 0-1.5-.4-3.6-1.2-6z"
                    className="pr-thumbs-fill"
                />
                <path
                    d="M71.5 62.6H48c-1.4 0-2.6.5-3.7 1.5s-1.5 2.3-1.5 3.7v52.1c0 1.4.5 2.6 1.5 3.7 1 1 2.3 1.5 3.7 1.5h23.5c1.4 0 2.6-.5 3.7-1.5 1-1 1.5-2.3 1.5-3.7V67.8c0-1.4-.5-2.6-1.5-3.7-1.1-1-2.3-1.5-3.7-1.5zm-9.4 50.6c-1 1-2.3 1.5-3.7 1.5-1.5 0-2.7-.5-3.7-1.5s-1.5-2.2-1.5-3.7c0-1.4.5-2.6 1.5-3.7 1-1 2.2-1.5 3.7-1.5 1.4 0 2.6.5 3.7 1.5 1 1 1.5 2.3 1.5 3.7.1 1.5-.4 2.7-1.5 3.7z"
                    className="pr-thumbs-cuff-fill"
                />
            </g>
        </svg>
    </span>
);
export default ThumpsUpIcon;
