import { gql, TypedDocumentNode } from '@apollo/client';
import {
    CreateAnswerVoteInput,
    CreateAnswerVoteResponse,
} from 'ui/util/type-helper';

export const CREATE_ANSWER_VOTE_MUTATION: TypedDocumentNode<CreateAnswerVoteResponse, CreateAnswerVoteInput> = gql`
    mutation createAnswerVote(
        $vote: Int!,
        $answerId: Int!,
        $customerId: Int!,
        $voteId: Int
    ) {
        createAnswerVote(
            input: {
                vote: $vote,
                answer_id: $answerId,
                customer_id: $customerId
                vote_id: $voteId
            }
        ) {
            vote_id
            vote
            answer_id
            customer_id
            thumbs_down_count
            thumbs_up_count
        }
    }
`;
