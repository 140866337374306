import { gql, TypedDocumentNode } from '@apollo/client';

export const FETCH_QUESTION_ANSWER: TypedDocumentNode = gql`
    query getQuestions(
        $pageType: String,
        $identifier: String,
        $displayAt: String,
        $pageSize: Int,
        $currentPage: Int,
        $sortId: String,
        $searchTerm: String,
    ) {
        getQuestions(
            input: {
                pageType: $pageType,
                identifier: $identifier,
                displayAt: $displayAt,
                pageSize: $pageSize,
                currentPage: $currentPage,
                sortId: $sortId,
                searchTerm: $searchTerm,
            }
        ) {
            items {
                question_id
                author
                email
                page_type
                question
                display_at
                identifier
                customer_id
                created_at
                disable_thread
                answers {
                    answer
                    answer_id
                    author
                    created_at
                    email
                    votes {
                        vote
                        vote_id
                        customer_id
                        created_at
                    }
                }
            }
            total_count
            page_info {
                page_size
                current_page
                total_pages
            }
            search_term
            sort_order
        }
    }
`;
