import React, { useState, KeyboardEvent } from 'react';
import playVideoIcon from 'assets/images/video-play.svg';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Modal } from 'ui/component/native-reviews/display/display/modal-image';
import { ReviewCard } from 'ui/component/native-reviews/display/display//media-card';
import style from 'ui/component/native-reviews/display/style.css';

export const ReviewMedias = ({ review }) => {
    const t = usePhraseTranslater();
    const [selectedMediaIndex, setSelectedMediaIndex] = useState<number | null>(null);
    const VIDEO_TYPE = '2';

    const openMedia = (index: number) => {
        setSelectedMediaIndex(index);
    };

    const closeModal = () => {
        setSelectedMediaIndex(null);
    };

    const handlePrevNextMedia = (delta: number) => {
        if (selectedMediaIndex !== null) {
            const newIndex = selectedMediaIndex + delta;
            if (newIndex >= 0 && newIndex < review.media.length) {
                setSelectedMediaIndex(newIndex);
            }
        }
    };

    if (!review.media || review.media.length === 0) {
        return null;
    }

    const selectedMedia = selectedMediaIndex !== null ? review.media[selectedMediaIndex] : null;

    return (
        <div className={style.reviewImagesContainer}>
            {review.media.map((media, index) => (
                <div
                    key={media.entity_id || `media-${index}`}
                    tabIndex={0}
                    role="button"
                    onClick={() => openMedia(index)}
                    onKeyDown={(e: KeyboardEvent) => {
                         if (e.key === 'Enter') {
                             openMedia(index);
                         }
                     }}
                >
                    <figure
                        key={media.entity_id || index}
                        className={style.reviewImageWrapper}
                    >
                        {media.type === VIDEO_TYPE && (
                            <img src={playVideoIcon} className={style.playVideo} alt="" />
                        )}
                        <img
                            src={media.video_poster_path || media.thumbnail_image}
                            alt={media.caption || ''}
                            className={style.reviewImage}
                        />
                        <figcaption className={style.mediaCaption}>{media.caption}</figcaption>
                    </figure>
                </div>
            ))}
            {selectedMedia && (
                <Modal isOpen={!!selectedMedia} onClose={closeModal}>
                    <div className={style.imageWrapper}>
                        {selectedMedia.type === VIDEO_TYPE ? (
                            // eslint-disable-next-line jsx-a11y/media-has-caption
                            <video
                                key={selectedMediaIndex}
                                src={selectedMedia.media_path}
                                className={style.fullSizeImage}
                                controls
                                onLoadStart={(e) => {
                                    const videoElement = e.target as HTMLVideoElement;
                                    videoElement.pause();
                                    videoElement.currentTime = 0;
                                }}
                            />
                        ) : (
                            <img
                                src={selectedMedia.full_image}
                                alt={selectedMedia.caption || t('Full size')}
                                className={style.fullSizeImage}
                            />
                        )}
                    </div>
                    <ReviewCard review={review} />
                    <div>
                        {selectedMediaIndex !== null && selectedMediaIndex > 0 && (
                            <button
                                type="button"
                                className={`${style.navButton} ${style.left}`}
                                onClick={() => handlePrevNextMedia(-1)}
                                aria-label={t('Previous Media')}
                            />
                        )}
                        {selectedMediaIndex !== null && selectedMediaIndex < review.media.length - 1 && (
                            <button
                                type="button"
                                className={`${style.navButton} ${style.right}`}
                                onClick={() => handlePrevNextMedia(1)}
                                aria-label={t('Next Media')}
                            />
                        )}
                    </div>
                </Modal>
            )}
        </div>
    );
};
