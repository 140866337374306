import React from 'react';
import featureBack from 'assets/images/featureBack.png';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

const getFeatures = (product) => {
    const ret = product.attributes
        .filter(x => x.code.indexOf('feature_icons') === 0)
        .filter(x => x.value !== null && x.value.option_text && x.value.value !== '')
        .map(x => x.value);
    if (ret.length === 0) {
        return [];
    }
    let appendNounce = 0;
    if (ret.length <= 4) {
        appendNounce = 4 - ret.length;
    } else {
        appendNounce = 8 - ret.length;
    }
    // eslint-disable-next-line no-plusplus
    while (appendNounce--) {
        ret.push({ type: 'nounce' });
    }
    return ret;
};

// Nothing else to use as key for this one
/* eslint-disable react/no-array-index-key */
export const Features = ({ product }) => {
    const t = usePhraseTranslater();
    const features = getFeatures(product);
    if (features.length === 0) {
        return null;
    }
    return (
        <div className={styles.FeatureWrap}>
            <div style={{ backgroundImage: featureBack }}>
                <p className={styles.head}>{t('FEATURES')}</p>
                <ul className={styles.icons}>
                    {features.map((item, index) => {
                        if (item.type === 'nounce') {
                            return <li key={index} />;
                        }

                        return (
                            <li key={index}>
                                <div className={styles.featureIcon}><img src={item.icon} alt="" /></div>
                                <p className={styles['p-head']}>{item.type}</p>
                                <p className={styles['features-item']}>{item.description}</p>
                            </li>
                        );
                   })}
                </ul>
            </div>
        </div>
    );
};
/* eslint-enable */
