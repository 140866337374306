import React from 'react';
import styles from './style.css';

/* The product description can contain HTML, so we need to
use dangerouslySetInnerHTML. This doesn't introduce an XSS
vulnerability because it can only be set by CW employees */

/* eslint-disable react/no-danger */
export const TextDescription = ({ product, simpleDesc }) => {
    const productDesc = simpleDesc.description || product.description;
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: productDesc,
            }}
            className={styles.textDescription}
            id="descri"
        />
    );
};
/* eslint-enable */
