import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import style from './style.css';

type RatingsCount = {
    fiveStar: number;
    fourStar: number;
    threeStar: number;
    twoStar: number;
    oneStar: number;
};
type ReviewMetrics = {
    averageRating: number;
    averageRatingRound: number;
    averageRecommends: number;
    averageSizing: number;
    reviewsCount: number;
    ratingsCount: RatingsCount;
};

type FilterProps = {
    applyFilter: (star: string) => void;
    clearFilter: () => void;
    filter: string | null;
    reviewMetrics: ReviewMetrics;
};

const Histogram = ({
    applyFilter,
    clearFilter,
    filter,
    reviewMetrics,
}: FilterProps) => {
    const t = usePhraseTranslater();
    const totalReviews = reviewMetrics?.reviewsCount;
    const ratingsCount = reviewMetrics?.ratingsCount;
    const starMapping: { [key in keyof RatingsCount]: string } = {
        fiveStar: '5',
        fourStar: '4',
        threeStar: '3',
        twoStar: '2',
        oneStar: '1',
    };
    return (
        <section className={style.histogramRoot} aria-labelledby="histogramroot">
            <div className={style.snapshotHistogram}>
                <h1 className={style.histogramHeadline}>Ratings Distribution</h1>
                <ul className={style.histogramList}>
                    {Object.entries(ratingsCount)
                        .filter(([key]) => key !== '__typename')
                        .sort(([a], [b]) => Number(starMapping[b as keyof RatingsCount]) - Number(
                            starMapping[a as keyof RatingsCount],
                        ))
                        .map(([starKey, count]) => {
                            const star = starMapping[starKey as keyof RatingsCount];
                            return (
                                <li
                                    key={star}
                                    className={`${style.listItems} ${count === 0 ? style.zeroRatings : ''}`}
                                >
                                    <div
                                        role="button"
                                        tabIndex={count > 0 ? 0 : -1}
                                        aria-label={t(`${count} Reviews ${star} Stars. Show only ${star} star reviews`)}
                                        className={`${style.starsCount} ${star} ${count === 0 ? style.noReviews : ''}`}
                                        onClick={() => count > 0 && applyFilter(star)}
                                        onKeyDown={(e) => {
                                            if ((e.key === 'Enter' || e.key === 'Space') && count > 0) {
                                                e.preventDefault();
                                                applyFilter(star);
                                            }
                                        }}
                                        aria-disabled={count === 0 ? 'true' : 'false'}
                                    >
                                        <p className={style.histogramLabel} aria-hidden="true">
                                            {star}
                                            {' '}
                                            Stars
                                        </p>
                                        <div
                                            data-tooltip={t(`Show only ${star} star reviews`)}
                                            className={`${style.barContainer} ${count === 0 ? style.noReviews : ''}`}
                                            aria-hidden="true"
                                        >
                                            <div className={style.histogramBar}>
                                                <div
                                                    className={`${style.barValue} ${filter === star ? style.barValueSelected : ''}`}
                                                    style={{ width: totalReviews > 0 ? `${(count / totalReviews) * 100}%` : '0%' }}
                                                />
                                            </div>
                                        </div>
                                        <p
                                            className={`${style.histogramCount} ${filter === star ? style.countSelected : ''}`}
                                            aria-hidden="true"
                                        >
                                            {count}
                                        </p>
                                        {filter === star && (
                                            <div
                                                className={style.histogramCross}
                                                aria-hidden="true"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    clearFilter();
                                                }}
                                            >
                                                <span className={style.filterClose}>
                                                    <svg viewBox="9.5 9.5 21 21" focusable="false">
                                                        <g className={style.filterCloseGroup}>
                                                            <circle
                                                                className={style.closeCircle}
                                                                cx="20"
                                                                cy="20"
                                                                r="10"
                                                                stroke="#333"
                                                                strokeWidth="1"
                                                                fill="none"
                                                            />
                                                            <line
                                                                className={style.closeLine}
                                                                x1="15"
                                                                y1="15"
                                                                x2="25"
                                                                y2="25"
                                                                stroke="#333"
                                                                strokeWidth="2"
                                                            />
                                                            <line
                                                                className={style.closeLine}
                                                                x1="25"
                                                                y1="15"
                                                                x2="15"
                                                                y2="25"
                                                                stroke="#333"
                                                                strokeWidth="2"
                                                            />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                </ul>
            </div>
        </section>
    );
};

export default Histogram;
