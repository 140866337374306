import React, { useState } from 'react';
import Button from 'ui/component/button/button';
import ThumpsUpIcon from 'ui/component/svg/thumps-up-icon/thumps-up-icon';
import ThumpsDownIcon from 'ui/component/svg/thumps-down-icon/thumps-down-icon';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import styles from './styles.css';

interface ThumbsUpDownProps {
    thumbsUpNumber: number;
    thumbsDownNumber: number;
    initialThumbsUp?: boolean;
    initialThumbsDown?: boolean;
    isLoggedIn?: boolean;
    onThumbsUpClick?: () => void;
    onThumbsDownClick?: () => void;
    disabled?: boolean;
    showSignInText?: boolean;
}

const ThumbsUpDown: React.FC<ThumbsUpDownProps> = ({
    thumbsUpNumber,
    thumbsDownNumber,
    initialThumbsUp = false,
    initialThumbsDown = false,
    isLoggedIn = false,
    onThumbsUpClick,
    onThumbsDownClick,
    disabled = false,
    showSignInText = false,
}) => {
    const [isThumbsUp, setIsThumbsUp] = useState(initialThumbsUp);
    const [isThumbsDown, setIsThumbsDown] = useState(initialThumbsDown);

    const t = usePhraseTranslater();

    const handleThumbsUp = () => {
        if (!isThumbsUp && isLoggedIn) {
            setIsThumbsUp(true);
            setIsThumbsDown(false); // Unselect thumbs-down if it was selected
            if (onThumbsUpClick) onThumbsUpClick();
        }

        if (!isLoggedIn) {
            if (onThumbsUpClick) onThumbsUpClick();
        }
    };

    const handleThumbsDown = () => {
        if (!isThumbsDown && isLoggedIn) {
            setIsThumbsDown(true);
            setIsThumbsUp(false);
            if (onThumbsDownClick) onThumbsDownClick();
        }
        if (!isLoggedIn) {
            if (onThumbsDownClick) onThumbsDownClick();
        }
    };

    return (
        <>
            <div className={styles.thumpsMainCnt}>
                <Button
                    className={classes(
                        styles.defaultBtn,
                        {
                            [styles.isThumbsUp]: isThumbsUp,
                            [styles.defaultThumps]: !isThumbsUp,
                            [styles.disabled]: disabled,
                        },
                    )}
                    onClick={handleThumbsUp}
                    disabled={disabled}
                >
                    <span>
                        <ThumpsUpIcon color="#45ad00" fill={isThumbsUp ? '#fff' : '#707070'} />
                    </span>
                    <span
                        className={classes(
                            styles.voteCounterCnt,
                            {
                                [styles.thumbsUpText]: isThumbsUp,
                            },
                        )}
                    >
                        {thumbsUpNumber}
                    </span>
                </Button>
                <Button
                    className={classes(
                        styles.defaultBtn,
                        {
                            [styles.isThumbsUp]: isThumbsDown,
                            [styles.defaultThumps]: !isThumbsDown,
                            [styles.disabled]: disabled,
                        },
                    )}
                    onClick={handleThumbsDown}
                    disabled={disabled}
                >
                    <span>
                        <ThumpsDownIcon color="#ff0000" fill={isThumbsDown ? '#fff' : '#707070'} />
                    </span>
                    <span
                        className={classes(
                            styles.voteCounterCnt,
                            {
                                [styles.thumbsDownText]: isThumbsDown,
                            },
                        )}
                    >
                        {thumbsDownNumber}
                    </span>
                </Button>
            </div>
            {showSignInText && <span className={styles.loginTextnt}>{t('Please login to react')}</span>}
        </>
    );
};

export default ThumbsUpDown;
