import React from 'react';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import uparrow from 'assets/images/arrow-up.svg';
import downarrow from 'assets/images/arrow-down.svg';
import screenSwitch from 'ui/styles/screen-switch.css';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { CareSection } from './components/care-section';
import { TradeMarks } from './components/trademarks';
import { Bullets } from './components/bullets';
import { ProductSheet } from './components/product-sheet';
import { HowToVideo } from './components/how-to-video';
import { TextDescription } from './components/text-description';
import styles from './style.css';

export const DesktopDescription = ({ product, simpleProduct }) => {
    const t = usePhraseTranslater();
    return (
        <UseState>
            {([open, setOpen]) => (
                <div className={screenSwitch.showOnDesktop}>
                    <div className={styles['description-wrap']}>
                        <p className={styles['description-head']}>{t('PRODUCT DESCRIPTION')}</p>
                        <div
                            className={classes(styles['description-main'], {
                                [styles.descriptionPreview]: !open,
                            })}
                        >
                            <div className={styles.leftBlock}>
                                <Bullets product={simpleProduct} />
                                <TradeMarks product={simpleProduct} />
                                <TextDescription product={product} simpleDesc={simpleProduct} />
                                <ProductSheet product={simpleProduct} />
                            </div>
                            <div className={styles.rightBlock}>
                                <HowToVideo product={simpleProduct} />
                                <CareSection product={simpleProduct} />
                            </div>
                            <div className={styles.clear} />
                        </div>
                        <AccessibleButton
                            tag="div"
                            className={classes(styles.toggleBtn, {
                                [styles.toggleBtnOpened]: open,
                                [styles.toggleBtnClosed]: !open,
                            })}
                            action={() => setOpen(!open)}
                        >
                            <span>{open ? t('Show Less') : t('Show More')}</span>
                            <img src={open ? uparrow : downarrow} alt="" />
                        </AccessibleButton>
                    </div>
                </div>
            )}
        </UseState>
    );
};
