import React, { useEffect, useState } from 'react';
// @ts-ignore
import { createPortal } from 'react-dom';
import { useMutation } from '@apollo/client';
import { CreateAnswerVoteInput, CreateAnswerVoteResponse, Vote } from 'ui/util/type-helper';
import { aggregateVotes, filterByCustomerId } from 'ui/util/validator-helper';
import ThumbsUpDown from 'ui/component/thumps-up-down/thumps-up-down';
import {
    LogoSignInPopupBox,
} from 'ui/component/embroidery-configurator/pages/logos/configure/logo/logo-on-file/logo-sign-in-popup-form';
import { getEmbeddedEmbConfiguratorElement } from 'ui/component/embroidery-configurator/util';
import { ModalSystemConsumer } from '@silkpwa/module/react-component/modal-system/consumer';
import { CREATE_ANSWER_VOTE_MUTATION } from 'graphql/question-answer/mutations/createUpdateVote';

interface ThumbsUpDownProps {
    answerId: number;
    customerId?: number;
    votes?: Vote[];
    showSignInText?: boolean;
}

const VoteIcon: React.FC<ThumbsUpDownProps> = ({
    answerId,
    customerId,
    votes = [],
    showSignInText = false,
}) => {
    const [addRating, setAddRating] =
        useState<{voteId?: number;thumbsUp: number;thumbsDown: number}>({
            voteId: undefined,
            thumbsUp: 0,
            thumbsDown: 0,
        });
    const [
        createAnswerVote,
    ] = useMutation<CreateAnswerVoteResponse, CreateAnswerVoteInput>(CREATE_ANSWER_VOTE_MUTATION);

    useEffect(() => {
        setAddRating(() => {
            const { thumbsUp, thumbsDown } = aggregateVotes(votes);
            return {
                voteId: undefined,
                thumbsUp,
                thumbsDown,
            };
        });
    }, [votes]);

    const onThumbsUpClick = async (voteId?: number|string) => {
        if (customerId) {
            const currentVoteId = voteId || addRating.voteId ? Number(voteId || addRating.voteId) : undefined;
            const requestBody: CreateAnswerVoteInput = {
                voteId: currentVoteId,
                vote: 1,
                answerId,
                customerId,
            };
            const { data } = await createAnswerVote({
                variables: requestBody,
            });
            if (data) {
                setAddRating(() => ({
                    voteId: data?.createAnswerVote?.vote_id,
                    thumbsUp: data?.createAnswerVote?.thumbs_up_count ?? 0,
                    thumbsDown: data?.createAnswerVote?.thumbs_down_count ?? 0,
                }));
            }
        }
    };

    const onThumbsDownClick = async (voteId?: number|string) => {
        if (customerId) {
            const currentVoteId = voteId || addRating.voteId ? Number(voteId || addRating.voteId) : undefined;
            const requestBody: CreateAnswerVoteInput = {
                voteId: currentVoteId,
                vote: -1,
                answerId,
                customerId,
            };
            const { data } = await createAnswerVote({
                variables: requestBody,
            });
            if (data) {
                setAddRating(() => ({
                    voteId: data?.createAnswerVote?.vote_id,
                    thumbsUp: data?.createAnswerVote?.thumbs_up_count ?? 0,
                    thumbsDown: data?.createAnswerVote?.thumbs_down_count ?? 0,
                }));
            }
        }
    };

    if (!customerId) {
        if (showSignInText) {
            return (
                <ThumbsUpDown
                    thumbsUpNumber={addRating.thumbsUp}
                    thumbsDownNumber={addRating.thumbsDown}
                    showSignInText
                />
            );
        }

        return (
            <ModalSystemConsumer>
                {({ open, setOpen }: {open: boolean; setOpen: (v: boolean) => void}) => (
                    <>
                        <ThumbsUpDown
                            thumbsUpNumber={addRating.thumbsUp}
                            thumbsDownNumber={addRating.thumbsDown}
                            onThumbsUpClick={() => setOpen(true)}
                            onThumbsDownClick={() => setOpen(true)}
                        />
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
                        {open && createPortal(
                            <LogoSignInPopupBox
                                handleClose={() => setOpen(false)}
                            />,
                            getEmbeddedEmbConfiguratorElement(),
                        )}
                    </>
                )}
            </ModalSystemConsumer>
        );
    }

    if (votes) {
        const data = filterByCustomerId(votes, Number(customerId));
        if (data && data.length > 0) {
            return (
                <ThumbsUpDown
                    thumbsUpNumber={addRating.thumbsUp}
                    thumbsDownNumber={addRating.thumbsDown}
                    initialThumbsUp={data[0]?.thumbsUp}
                    initialThumbsDown={data[0]?.thumbsDown}
                    onThumbsUpClick={() => onThumbsUpClick(data[0]?.vote_id)}
                    onThumbsDownClick={() => onThumbsDownClick(data[0]?.vote_id)}
                    isLoggedIn
                />
            );
        }
        return (
            <ThumbsUpDown
                thumbsUpNumber={addRating.thumbsUp}
                thumbsDownNumber={addRating.thumbsDown}
                onThumbsUpClick={() => onThumbsUpClick()}
                onThumbsDownClick={() => onThumbsDownClick()}
                isLoggedIn
            />
        );
    }

    return (
        <ThumbsUpDown
            thumbsUpNumber={addRating.thumbsUp}
            thumbsDownNumber={addRating.thumbsDown}
            onThumbsUpClick={onThumbsUpClick}
            onThumbsDownClick={onThumbsDownClick}
            isLoggedIn
        />
    );
};

export default VoteIcon;
