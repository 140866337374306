import React from 'react';

import styles from './styles.css';

interface IQuestionTextProps {
    text: string;
    className?: string;
    children?: React.ReactNode;
}

const QuestionText: React.FC<IQuestionTextProps> = ({
    text,
    className,
    children,
}) => (
    <div className={`${styles.defaultQuesCnt} ${className}`}>
        <div className={`${styles.questionTextCntCss} question-text`}>
            <abbr className={styles.prQAaDisplayItemIcon} title="Question" aria-label="Question">Q</abbr>
            <h2 className={styles.qaTextCss}>{text}</h2>
        </div>
        {children}
    </div>
);

export default QuestionText;
