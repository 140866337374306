import React from 'react';
import { AspectRatio } from '@silkpwa/module/react-component/aspect-ratio';
import { reservedHeight } from 'ui/styles/reserved-height';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { getVideo } from './get-video';
import styles from './style.css';

export const HowToVideo = ({ product }) => {
    const t = usePhraseTranslater();
    const video = getVideo(product);
    if (!video) return null;

    return (
        <div className={styles.videoBlock}>
            <div className={styles.descriptionLabel}>{t('How-To')}</div>
            <AspectRatio ratio="16:9" additionalReservedHeight={reservedHeight}>
                <iframe
                    src={video}
                    title={t('How To')}
                    allowFullScreen
                    className={styles.video}
                />
            </AspectRatio>
        </div>
    );
};
