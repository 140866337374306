import { gql, TypedDocumentNode } from '@apollo/client';

export const GET_REVIEW_MEDIA_SLIDER: TypedDocumentNode = gql`
    query GET_REVIEW_MEDIA_SLIDER($productId: Int!) {
        reviewMediaSlider(productId: $productId) {
            entity_id
            full_image
            media_path
            thumbnail_image
            video_poster_path
            type
            caption
            review_id
            review {
                rating_votes {
                    value
                }
                title
                detail
            }
        }
    }
`;
