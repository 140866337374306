import { gql, TypedDocumentNode } from '@apollo/client';
import {
    CreateAnswerInput,
    CreateAnswerResponse,
} from 'ui/util/type-helper';

export const CREATE_ANSWER_MUTATION: TypedDocumentNode<CreateAnswerResponse, CreateAnswerInput> = gql`
    mutation CreateAnswer(
        $author: String!,
        $email: String!,
        $answer: String!,
        $displayAt: Int!,
        $questionId: Int!
        $customerId: Int
    ) {
        createAnswer(
            author: $author,
            email: $email,
            answer: $answer,
            displayAt: $displayAt,
            questionId: $questionId,
            customerId: $customerId,
        ) {
            answerId
            author
            answer
            email
        }
    }
`;
