const bulletAttributes = {
    bullets_fabric_blend: true,
    bullets_fabric_weight: true,
    bullets_alt_colors: true,
    bullets_size: true,
};

const featureSort = (a, b) => {
    const skip = 'bullets_feature_'.length;
    const aNum = Number.parseInt(a.code.substring(skip), 10);
    const bNum = Number.parseInt(b.code.substring(skip), 10);

    return aNum - bNum;
};

const getFeatures = product => product.attributes
    .filter(x => x.code.indexOf('bullets_feature_') === 0)
    .filter(x => x.value !== null)
    .sort(featureSort)
    .map(x => x.value);

const getBulletAttributes = product => product.attributes
    .filter(x => bulletAttributes[x.code])
    .filter(x => x.value !== null)
    .reduce((acc, x) => {
        const k = x.code.substring('bullets_'.length);
        acc[k] = x.value;
        return acc;
    }, {});

export const getBullets = product => ({
    features: getFeatures(product),
    attributes: getBulletAttributes(product),
});
