import React, { useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { Slider } from '@silkpwa/module/react-component/slider';
import { Swiper } from '@silkpwa/module/react-component/swiper';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { Modal } from 'ui/component/native-reviews/display/display/modal-image';
import playVideoIcon from 'assets/images/video-play.svg';
import { ReviewCard } from 'ui/component/native-reviews/display/display/media-card';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import style from 'ui/component/native-reviews/display/style.css';

/* eslint-disable camelcase */
interface MediaItem {
    entity_id: number;
    full_image: string;
    media_path: string;
    thumbnail_image: string;
    video_poster_path: string;
    type: string;
    caption?: string;
    review_id: number;
    review: Review;
}

interface Review {
    rating_votes: RatingVotes[];
    title: string;
    detail: string;
}

interface RatingVotes {
    value: number;
}
/* eslint-enable camelcase */

interface ReviewMediasSliderProps {
    reviewMediaSliderData: MediaItem[];
    show?: number;
    className?: string;
    itemsClassName?: string;
    leftArrow?: React.ReactNode;
    rightArrow?: React.ReactNode;
    arrowClassName?: string;
}

export const ReviewMediasSlider: React.FC<ReviewMediasSliderProps> = ({
    reviewMediaSliderData,
    show = 7,
    className,
    itemsClassName,
    leftArrow,
    rightArrow,
    arrowClassName,
}) => {
    const VIDEO_TYPE = '2';
    const [selectedMedia, setSelectedMedia] = useState<MediaItem | null>(null);
    const [selectedReview, setSelectedReview] = useState<Review | null>(null);
    const [selectedMediaIndex, setSelectedMediaIndex] = useState<number | null>(null);

    const allMediaItems = reviewMediaSliderData;
    const t = usePhraseTranslater();
    if (allMediaItems.length === 0) {
        return null;
    }

    const handleItemClick = (media: MediaItem, review: Review, index: number) => {
        setSelectedMedia(media);
        setSelectedReview(review);
        setSelectedMediaIndex(index);
    };

    const closeModal = () => {
        setSelectedMedia(null);
        setSelectedReview(null);
        setSelectedMediaIndex(null);
    };

    const handlePrevNextMedia = (delta: number) => {
        if (selectedMediaIndex !== null) {
            const newIndex = selectedMediaIndex + delta;
            if (newIndex >= 0 && newIndex < allMediaItems.length) {
                const newMedia = allMediaItems[newIndex];
                setSelectedMedia(newMedia);
                setSelectedReview(newMedia.review);
                setSelectedMediaIndex(newIndex);
            }
        }
    };

    return (
        <div className={style.mediasSliderWrapper}>
            <Slider items={allMediaItems} show={show}>
                {({
                      items: displayedItems,
                      canSlide,
                      prev,
                      next,
                  }) => (
                      <Swiper next={next} prev={prev} threshold={10}>
                          {
                              (
                                  {
                                      onMouseDown, onTouchStart, onMouseUp, onTouchEnd,
                                  },
                              ) => (
                                  <div
                                      className={classes(style.itemMediaSlider, {
                                          [className]: className,
                                      })}
                                      onMouseDown={onMouseDown}
                                      onTouchStart={onTouchStart}
                                      onMouseUp={onMouseUp}
                                      onTouchEnd={onTouchEnd}
                                      role="button"
                                      tabIndex={0}
                                  >
                                      {canSlide && (
                                          <div className={`${style.arrowWrap} ${style.arrowLeft}`}>
                                              <AccessibleButton
                                                  tag="div"
                                                  className={`${style.arrow}`}
                                                  action={prev}
                                              >
                                                  {leftArrow || (
                                                      <i
                                                          className={classes(
                                                              'material-icons',
                                                              arrowClassName,
                                                              style.backArrow,
                                                          )}
                                                      >
                                                          arrow_back_ios
                                                      </i>
                                                  )}
                                              </AccessibleButton>
                                          </div>
                                      )}
                                      {canSlide && (
                                          <div className={`${style.arrowWrap} ${style.arrowRight}`}>
                                              <AccessibleButton
                                                  tag="div"
                                                  className={`${style.arrow}`}
                                                  action={next}
                                              >
                                                  {rightArrow || (
                                                      <i
                                                          className={classes(
                                                              'material-icons',
                                                              arrowClassName,
                                                              style.forwardArrow,
                                                          )}
                                                      >
                                                          arrow_forward_ios
                                                      </i>
                                                  )}
                                              </AccessibleButton>
                                          </div>
                                      )}
                                      <div className={`${style.products} ${itemsClassName}`}>
                                          {displayedItems.map((media, i) => {
                                              const associatedReview = media.review;
                                              return (
                                                  <button
                                                      className={style.mediaSlide}
                                                      key={media.entity_id || `media-${i}`}
                                                      onClick={() => handleItemClick(
                                                          media, associatedReview!, allMediaItems.indexOf(media),
                                                      )}
                                                      type="button"
                                                      tabIndex={0}
                                                  >
                                                      {media.type === VIDEO_TYPE ? (
                                                          <div>
                                                              <img src={playVideoIcon} className={style.playVideo} alt="" />
                                                              <img
                                                                  src={media.video_poster_path}
                                                                  alt={media.caption || ''}
                                                                  className={style.fullSizeImageSlider}
                                                              />
                                                          </div>
                                                      ) : (
                                                          <img
                                                              src={media.full_image}
                                                              alt={media.caption || ''}
                                                              className={style.fullSizeImageSlider}
                                                          />
                                                      )}
                                                  </button>
                                              );
                                          })}
                                      </div>
                                  </div>
                          )}
                      </Swiper>
                )}
            </Slider>

            {selectedMedia && (
                <Modal isOpen={!!selectedMedia} onClose={closeModal}>
                    <div className={style.imageWrapper}>
                        {selectedMedia.type === VIDEO_TYPE ? (
                            // eslint-disable-next-line jsx-a11y/media-has-caption
                            <video
                                key={selectedMediaIndex}
                                src={selectedMedia.media_path}
                                className={style.fullSizeImage}
                                controls
                                onLoadStart={(e) => {
                                    const videoElement = e.target as HTMLVideoElement;
                                    videoElement.pause();
                                    videoElement.currentTime = 0;
                                }}
                            />
                        ) : (
                            <img
                                src={selectedMedia.full_image}
                                alt={selectedMedia.caption || t('Full size')}
                                className={style.fullSizeImage}
                            />
                        )}
                    </div>
                    {selectedReview && (
                        <ReviewCard review={selectedReview} />
                    )}
                    <div>
                        {selectedMediaIndex !== null && selectedMediaIndex > 0 && (
                            <button
                                type="button"
                                className={`${style.navButton} ${style.left}`}
                                onClick={() => handlePrevNextMedia(-1)}
                                aria-label={t('Previous Media')}
                            />
                        )}
                        {selectedMediaIndex !== null && selectedMediaIndex < allMediaItems.length - 1 && (
                            <button
                                type="button"
                                className={`${style.navButton} ${style.right}`}
                                onClick={() => handlePrevNextMedia(1)}
                                aria-label={t('Next Media')}
                            />
                        )}
                    </div>
                </Modal>
            )}
        </div>
    );
};
