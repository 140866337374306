import React, { useEffect, useState } from 'react';
import { IProduct } from '@silkpwa/module/react-component/connect-product/connect-product';
import { RichSnippet } from './rich-snippet';


interface IProductRichSnippetProps {
    product: IProduct;
}

export interface IProductSnippetData {
    '@context': string;
    '@type': string;
}

/**
 * Adds the product rich snippet (https://schema.org/Product)  to the frontend of the website
 * @param product
 * @constructor
 */

export const ProductRichSnippet: React.FC<IProductRichSnippetProps> = ({ product }) => {
    const [data, setData] = useState(null);
    useEffect(() => {
        const getData = async () => {
            const response = await fetch(`${process.env.MAGENTO_API}/rest/V1/product-rich-snippet/${product.id}`);
            if (!response.ok) {
                throw new Error(
                    `Unable to fetch product snippet data ${response.status}`,
                );
            }
            const result = await response.json();
            setData(result);
        };
        getData();
    }, []);
    return <RichSnippet data={data} />;
};
