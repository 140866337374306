import React from 'react';
import van from 'assets/images/satisfaction_returns/van.png';
import or from 'assets/images/satisfaction_returns/or.png';
import { Container } from 'ui/component/container';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const ReturnsContent = () => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.popout}>
            <div className={styles.popoutBody}>
                <div className={styles.title}>
                    <img src={van} alt="" />
                    {t('Easy Returns')}
                </div>
                <div className={styles.header}>
                    {t(`Simply Obtain a Return Authorization Number, and Write 
On Package Before Returning.`)}
                </div>
                <div className={styles.methods}>
                    <img className={styles.or} src={or} alt="" />
                    <div className={styles.phoneMethod}>
                        <Container id="returns-phone-content" />
                    </div>
                    <div className={styles.downloadMethod}>
                        <Container id="returns-download-method" />
                    </div>
                </div>
            </div>
            <div className={styles.popoutFooter}>
                <Container id="returns-address-and-link-content" />
            </div>
        </div>
    );
};
