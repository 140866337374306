import React, { useState } from 'react';
// @ts-ignore
import { createPortal } from 'react-dom';
import { useQuery } from '@apollo/client';
import { connectAccount } from '@silkpwa/module/react-component/connect-account';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { classes } from '@silkpwa/module/util/classes';
import { SectionTitle } from 'ui/component/section-title/section-title';
import QuestionText from 'ui/component/question-answer/display/question-text/question-text';
import AnswerText from 'ui/component/question-answer/display/answer-text/answer-text';
import { SearchField } from 'ui/component/search-field/search-field';
import { DropDown } from 'ui/component/drop-down';
import AnswerForm from 'ui/component/question-answer/answer/answer-form';
import Pagination from 'ui/component/PaginationV1/Pagination';
import { FETCH_QUESTION_ANSWER } from 'graphql/question-answer';
import { TData, TQuestion } from 'ui/util/type-helper';
import { getSortOptions, timeAgo } from 'ui/util/validator-helper';
import VoteIcon from 'ui/component/question-answer/display/vote/vote-icon';
import {
    LogoSignInPopupBox,
} from 'ui/component/embroidery-configurator/pages/logos/configure/logo/logo-on-file/logo-sign-in-popup-form';
import { getEmbeddedEmbConfiguratorElement } from 'ui/component/embroidery-configurator/util';
import { ModalSystemConsumer } from '@silkpwa/module/react-component/modal-system/consumer';

import style from './styles.css';

interface QuestionAnswerDisplayProps {
    account: AccountState;
    pageType: string;
    identifier: string;
    displayAt: string;
    pageSize: number;
    currentPage: number;
    showSignInText?: boolean;
}

const QuestionAnswerDisplay: React.FC<QuestionAnswerDisplayProps> = ({
    account,
    pageType,
    identifier,
    displayAt,
    pageSize = 5,
    currentPage = 1,
    showSignInText,
}) => {
    const {
        loading, error, data, refetch,
    } = useQuery<TData>(FETCH_QUESTION_ANSWER, {
        variables: {
            pageType,
            identifier,
            displayAt,
            pageSize,
            currentPage,
            searchTerm: null,
            sortId: 'date-desc',
        },
        notifyOnNetworkStatusChange: true,
    });

    const [activeQuestionId, setActiveQuestionId] = useState<number | null>(null);

    const storeConfig = useConfig();
    const currentStoreId = storeConfig.store_config.current_store.id;
    const extAttribute = storeConfig.extension_attributes;
    const customerId = account.isLoggedIn ? account?.info?.id : undefined;

    const onSearchTermSubmit = (searchString: string|null) => {
        refetch({
            searchTerm: searchString,
        });
    };

    const getSortOptionsArray = () => {
        const options = getSortOptions();
        return options.filter(option => option.id === 'date-desc' || option.id === 'date-asc');
    };

    const handleOptionChange = (option: { id: string; value: string}) => {
        refetch({
            sortId: option.id,
        });
    };

    const handlePageChange = (page: number) => {
        refetch({
            currentPage: page,
        });
    };

    const toggleAnswerForm = (questionId: number) => {
        setActiveQuestionId(prevId => (prevId === questionId ? null : questionId));
    };

    const t = usePhraseTranslater();

    const returnAnswerForm = (question: TQuestion) => {
        if (
            extAttribute?.cw_enabled_recaptcha_for_answer &&
            extAttribute?.recaptcha_public_key
        ) {
            return (
                <AnswerForm
                    questionId={question.question_id}
                    buttonText={t('Submit Answer')}
                    storeId={currentStoreId}
                    customerId={customerId}
                    customerEmail={account.isLoggedIn ? account?.info?.email : undefined}
                    customerNickname={account.isLoggedIn ? `${account?.info?.firstName} ${account?.info?.lastName}` : undefined}
                    setActiveQuestionId={setActiveQuestionId}
                    reCaptcWebsiteKey={extAttribute?.recaptcha_public_key}
                />
            );
        }

        return (
            <AnswerForm
                questionId={question.question_id}
                buttonText={t('Submit Answer')}
                storeId={currentStoreId}
                customerId={customerId}
                customerEmail={account.isLoggedIn ? account?.info?.email : undefined}
                customerNickname={account.isLoggedIn ? `${account?.info?.firstName} ${account?.info?.lastName}` : undefined}
                setActiveQuestionId={setActiveQuestionId}
            />
        );
    };

    if (loading) return <p>{t('Loading...')}</p>;
    if (error) {
        return null;
    }

    if (data && data?.getQuestions?.total_count < 1 && data?.getQuestions?.search_term === null) return null;

    return (
        <>
            <div
                id="question-answer-display"
                className={classes(style.quenstionAnswerDisplay, style.rdDisplayDesktop)}
            >
                <div>
                    <SectionTitle
                        id="qa-main-header"
                    >
                        <h2 className={style.qaSectionTitle}>
                            {t('Questions and Answers')}
                        </h2>
                        <div className={style.chefIcon} />
                    </SectionTitle>
                    <div className={style.filterCntCss}>
                        <div className={style.filterLabelCss}>{t('Search for answers to your question')}</div>
                        <SearchField
                            onSearch={onSearchTermSubmit}
                            value={data?.getQuestions?.search_term || ''}
                            id="search-term"
                        />
                    </div>
                    <div className={style.sortCntCss}>
                        <div className={style.sortGroupCntCss}>
                            <DropDown
                                label={t('Sort by')}
                                options={getSortOptionsArray()}
                                selected={data?.getQuestions?.sort_order || 'date-desc'}
                                handleSelect={handleOptionChange}
                            />
                        </div>
                    </div>
                    {data?.getQuestions?.items.map((question: TQuestion) => (
                        <div key={question.question_id} className={style.qaMainCnt}>
                            <div className={style.qaRightDesktopCnt}>
                                <div className={style.qaAuthorDesktopView}>
                                    <sub>{`${question.author} by ${timeAgo(question.created_at)}`}</sub>
                                </div>
                            </div>
                            <div className={style.qaLeftCnt}>
                                <QuestionText text={question.question}>
                                    <div className={style.qaAuthorMobileView}>{question.author}</div>
                                </QuestionText>

                                <div className={style.answerMainCntCss}>
                                    {(storeConfig.extension_attributes?.cw_allow_guest_to_answer ||
                                            account.isLoggedIn
                                    ) ? (
                                        <>
                                            {
                                                question.disable_thread === 1 ? null : (
                                                    <div className={style.qaAnsReplyCnt}>
                                                        <button
                                                            type="button"
                                                            className={style.prBtnAnswer}
                                                            onClick={() => toggleAnswerForm(question.question_id)}
                                                        >
                                                            <span
                                                                className={
                                                        activeQuestionId === question.question_id
                                                            ? style.prCrossActiveIcon
                                                            : style.prCrossIcon}
                                                            >
                                                                <svg viewBox="9.5 9.5 21 21" focusable="false">
                                                                    <g className="pr-cross-icon__group">
                                                                        <circle
                                                                            className="pr-cross-icon__circle"
                                                                            cx="20"
                                                                            cy="20"
                                                                            r="10"
                                                                            stroke="#333"
                                                                            strokeWidth="1"
                                                                            fill="none"
                                                                        />
                                                                        <line
                                                                            className="pr-cross-icon__line"
                                                                            x1="15"
                                                                            y1="15"
                                                                            x2="25"
                                                                            y2="25"
                                                                            stroke="#333"
                                                                            strokeWidth="2"
                                                                        />
                                                                        <line
                                                                            className="pr-cross-icon__line"
                                                                            x1="25"
                                                                            y1="15"
                                                                            x2="15"
                                                                            y2="25"
                                                                            stroke="#333"
                                                                            strokeWidth="2"
                                                                        />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            <span className={`pr-qa-display-text ${style.ansReplyLinkCnt}`}>{t('Add your answer')}</span>
                                                        </button>
                                                    </div>
                                                )
                                            }

                                            <div className={style.qaAnsReplyFormCnt}>
                                                {
                                                    activeQuestionId === question.question_id &&
                                                    returnAnswerForm(question)
                                                }
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {
                                                question.disable_thread === 1 ? null : (
                                                    <ModalSystemConsumer>
                                                        {/* eslint-disable-next-line max-len */}
                                                        {({ open, setOpen }: {open: boolean; setOpen: (v: boolean) => void}) => (
                                                            <>
                                                                <div className={style.qaAnsReplyCnt}>
                                                                    <button
                                                                        type="button"
                                                                        className={style.prBtnAnswer}
                                                                        onClick={() => setOpen(true)}
                                                                    >
                                                                        <span
                                                                            className={style.prCrossIcon}
                                                                        >
                                                                            <svg viewBox="9.5 9.5 21 21" focusable="false">
                                                                                <g className="pr-cross-icon__group">
                                                                                    <circle
                                                                                        className="pr-cross-icon__circle"
                                                                                        cx="20"
                                                                                        cy="20"
                                                                                        r="10"
                                                                                        stroke="#333"
                                                                                        strokeWidth="1"
                                                                                        fill="none"
                                                                                    />
                                                                                    <line
                                                                                        className="pr-cross-icon__line"
                                                                                        x1="15"
                                                                                        y1="15"
                                                                                        x2="25"
                                                                                        y2="25"
                                                                                        stroke="#333"
                                                                                        strokeWidth="2"
                                                                                    />
                                                                                    <line
                                                                                        className="pr-cross-icon__line"
                                                                                        x1="25"
                                                                                        y1="15"
                                                                                        x2="15"
                                                                                        y2="25"
                                                                                        stroke="#333"
                                                                                        strokeWidth="2"
                                                                                    />
                                                                                </g>
                                                                            </svg>
                                                                        </span>
                                                                        <span className={`pr-qa-display-text ${style.ansReplyLinkCnt}`}>{t('Login to add your answer')}</span>
                                                                    </button>
                                                                </div>
                                                                {/* eslint-disable-next-line max-len */}
                                                                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
                                                                {open && createPortal(
                                                                    <LogoSignInPopupBox
                                                                        handleClose={() => setOpen(false)}
                                                                    />,
                                                                    getEmbeddedEmbConfiguratorElement(),
                                                                )}
                                                            </>
                                                        )}
                                                    </ModalSystemConsumer>
                                                )
                                            }

                                            <div className={style.qaAnsReplyFormCnt}>
                                                {
                                                    activeQuestionId === question.question_id &&
                                                    returnAnswerForm(question)
                                                }
                                            </div>
                                        </>
                                    )}

                                    {question?.answers.map(answer => (
                                        <AnswerText
                                            author={answer.author}
                                            createdAt={answer.created_at}
                                            isVerifiedUser={!!answer.customer_id}
                                            text={answer.answer}
                                            key={answer.answer_id}
                                        >
                                            <VoteIcon
                                                answerId={answer.answer_id}
                                                customerId={customerId}
                                                votes={answer.votes}
                                                showSignInText={showSignInText}
                                            />
                                        </AnswerText>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                    { data?.getQuestions?.page_info && (
                        <div className={style.paginationCntCss}>
                            <Pagination
                                currentPage={data?.getQuestions?.page_info?.current_page}
                                totalPages={data?.getQuestions?.page_info?.total_pages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

const ConnectedQuestionAnswerDisplay = connectAccount(QuestionAnswerDisplay);

export { ConnectedQuestionAnswerDisplay as QuestionAnswerDisplay };
