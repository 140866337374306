import React from 'react';
import { getItem, ITradeMarkItem } from './get-item';
import { getTrademarks } from './get-trademarks';
import styles from './style.css';

const TradeMarkItem: React.FC<{ item: ITradeMarkItem }> = ({ item }) => (
    <img src={item.src} title={item.title} alt={item.title} />
);

const TradeMarkList: React.FC<{ trademarks: Array<string> }> = ({ trademarks }) => (
    <div className={styles.trade}>
        {trademarks.map(item => (
            <TradeMarkItem key={item} item={getItem(item)} />
        ))}
    </div>
);

export const TradeMarks = ({ product }) => {
    const trademarks = getTrademarks(product);

    return (
        <TradeMarkList trademarks={trademarks} />
    );
};
