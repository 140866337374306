import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Section } from '../../components/section';
import { StarRating } from '../../snippet/snippet/rating';
import { SizingSliderSnapshot } from './sizing';
import Histogram from './histogram/histogram';
import style from './style.css';

export const Snapshot = ({
    applyFilter,
    clearFilter,
    filter,
    reviewMetrics,
}) => {
    let {
        averageRating,
    } = reviewMetrics;
    const {
        averageRecommends,
        averageSizing,
        averageWidth,
        reviewsCount,
    } = reviewMetrics;
    if (averageRating.toString().length === 1) {
        averageRating = averageRating.toFixed(1);
    }
    const t = usePhraseTranslater();
    const customDivStyle = { display: reviewsCount > 0 ? 'flex' : 'none' };
    const averagePercentage = (averageRating / 5) * 100;
    return (
        <>
            <Section className={style.Header}>
                <h1 className={style.HeaderText}>Product Reviews</h1>
                <div className={style.Icon} />
            </Section>
            <div className={style.rowOne}>
                <Section className={style.section}>
                    <div className={style.colOne}>
                        <h1>{averageRating}</h1>
                        <div className={style.reviewSnippetContainer}>
                            <div
                                className={classes(
                                    style.snippetStars,
                                    style.snippetStarsPng,
                                )}
                                role="img"
                            >
                                <div className={style.ratingStars}>
                                    <StarRating ratingPercentage={averagePercentage} />
                                </div>
                            </div>
                        </div>
                        <div className={style.snippetReadAndWrite}>
                            <div className={style.snippetReviewCount}>
                                {reviewsCount}
                                {t(' Reviews')}
                            </div>
                            <button
                                className={style.button}
                                type="button"
                            >
                                <a
                                    href={`${window.location.href}#write-review`}
                                    className={classes(
                                        style.snippetWriteReviewLink,
                                    )}
                                    rel="nofollow"
                                >
                                    {t('Write a Review')}
                                </a>
                            </button>
                        </div>
                    </div>
                </Section>
                <Section id={style.sectionPercent} className={style.section}>
                    <div className={style.snippetStarsRecoReco} style={customDivStyle}>
                        <div className={style.snippetRecoToFriend}>
                            <div className={classes(
                                style.snippetRecoToFriendPercent,
                                style.snippetRecoToFriendGreen,
                            )}
                            >
                                <span className={style.checkboxIcon}>
                                    <svg viewBox="0 0 176 176" focusable="false" aria-hidden="true">
                                        <circle
                                            fill="#0A8900"
                                            stroke="white"
                                            strokeWidth="8"
                                            cx="88"
                                            cy="88"
                                            r="81.2"
                                        />
                                        <path
                                            fill="white"
                                            stroke="white"
                                            strokeWidth="5"
                                            d="M77.2 121.3L43 87.2l5.5-5.6 28.7 28.6 50.3-50.3 5.5 5.6"
                                        />
                                    </svg>
                                </span>
                                <div className={classes(style.reco, style.recoGreen)}>
                                    <span className={style.recoValue}>
                                        {`${averageRecommends}%`}
                                    </span>
                                </div>
                            </div>
                            <span className={style.recoToFriendMessage}>
                                {t('of respondents would recommend this to a friend')}
                            </span>
                        </div>
                    </div>
                </Section>
                <Section className={style.sectionHistogram}>
                    <div>
                        <Histogram
                            applyFilter={applyFilter}
                            clearFilter={clearFilter}
                            filter={filter}
                            reviewMetrics={reviewMetrics}
                        />
                    </div>
                </Section>
            </div>
            <div className={style.rowTwo}>
                <Section className={style.section}>
                    <div className={style.snippetSliderRoot} style={customDivStyle}>
                        <div className={style.snippetTitle}>{t('Sizing')}</div>
                        <div className={classes(style.sliderRoot, style.snippetSlider)}>
                            <div className={style.minText}>
                                {t('Feels full size' +
                                    ' too small')}
                            </div>
                            <div className={style.maxText}>
                                {t('Feels full size' +
                                    ' too big')}
                            </div>
                        </div>
                        <div className={style.slider} role="img">
                            <div className={style.sliderNodeGroup}>
                                <SizingSliderSnapshot
                                    averageSizing={averageSizing}
                                />
                            </div>
                            <div className={style.sliderLine} />
                        </div>
                    </div>
                </Section>
                <Section className={style.section}>
                    <div className={style.snippetSliderRoot} style={customDivStyle}>
                        <div className={style.snippetTitle}>{t('Width')}</div>
                        <div className={classes(style.sliderRoot, style.snippetSlider)}>
                            <div className={style.minText}>
                                {t('Feels too narrow')}
                            </div>
                            <div className={style.maxText}>{t('Feels too wide')}</div>
                        </div>
                        <div className={style.slider} role="img">
                            <div className={style.sliderNodeGroup}>
                                <SizingSliderSnapshot
                                    averageSizing={averageWidth}
                                />
                            </div>
                            <div className={style.sliderLine} />
                        </div>
                    </div>
                </Section>
            </div>
        </>
    );
};
