import React, { useState } from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { Section } from '../../components/section';
import style from '../style.css';

export const MoreDetailsButton = ({ widthValue, sizingValue }) => {
    const [showDetails, setShowDetails] = useState(false);
    const setLabelText = (text, value) => {
        if (value === 0) {
            return 'Reviewer did not report';
        }
        if (value <= text.length) {
            return text[value - 1];
        }
        return '';
    };
    const widthText = [
        'Feels too narrow',
        'Feels true to width',
        'Feels too wide',
    ];
    const sizingText = [
        'Feels full size too small',
        'Feels half size too small',
        'Feels true to size',
        'Feels half size too big',
        'Feels full size too big',
    ];
    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    return (
        <Section className={classes(
            style.rdContentBlock,
            style.accordion,
        )}
        >
            <AccessibleButton
                tag="button"
                className={classes(
                    style.accordionBtn,
                    `${showDetails ? style.accordionExpanded : ''}`,
                )}
                action={toggleDetails}
            >
                <span>{`${showDetails ? 'Less Details' : 'More Details'}`}</span>
                <span className={classes(style.caretIcon, `arrow-icon ${showDetails ? 'rotated' : ''}`)}>
                    <svg
                        viewBox="-9 -10 52 52"
                        focusable="false"
                        aria-hidden="true"
                    >
                        <g>
                            <polyline
                                className={style.caretIconLine}
                                fill="none"
                                stroke="#333"
                                strokeWidth="4"
                                points="23.7,31.5 8.3,16 23.7,0.5 "
                            />
                        </g>
                    </svg>
                </span>
            </AccessibleButton>
            <div
                aria-hidden="false"
                className={style.accordionContent}
                id="width-sizing-content"
            >
                <Section className={classes(
                    style.rdSubratings,
                    style.rdContentBlock,
                )}
                >
                    {showDetails && (
                        <>
                            <dl className={style.rdDefList}>
                                <dt>Width</dt>
                                <dd>{setLabelText(widthText, widthValue)}</dd>
                            </dl>
                            <dl className={style.rdDefList}>
                                <dt>Sizing</dt>
                                <dd>{setLabelText(sizingText, sizingValue)}</dd>
                            </dl>
                        </>
                    )}
                </Section>
            </div>
        </Section>
    );
};
