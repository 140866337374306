import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from 'ui/component/native-reviews/display/style.css';

export const Modal = ({
    isOpen, onClose, children, modalContent = styles.modalContent,
}) => {
    const t = usePhraseTranslater();
    if (!isOpen) return null;

    const handleOverlayClick = () => {
        onClose();
    };

    const handleContentClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className={styles.modalOverlay} onClick={handleOverlayClick} onKeyDown={handleOverlayClick} role="presentation">
            <button type="button" className={classes(styles.closeButton, styles.desktopOnly)} onClick={onClose} onKeyDown={onClose} aria-label={t('Close')} />
            <div className={modalContent} onClick={handleContentClick} onKeyDown={handleContentClick} role="presentation">
                {children}
            </div>
        </div>
    );
};
