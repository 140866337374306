import React from 'react';
import { AccordionGroupState } from '@silkpwa/module/react-component/accordion-group-state';
import box from 'assets/images/satisfaction_returns/box.png';
import approval from 'assets/images/satisfaction_returns/approval.png';
import rewards from 'assets/images/marketing/rewards_small.png';
import screenSwitch from 'ui/styles/screen-switch.css';
import { getProductAttribute } from 'ui/util/get-product-attribute';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { getDisplayRewards } from 'ui/util/get-display-rewards';
import { AccordionItem } from '../../components/accordion-item';
import { ReturnsContent } from '../../components/returns-content';
import { GuaranteeContent } from '../../components/guarantee-content';
import { RewardsContent } from '../../components/rewards-content';
import { MobileDescription } from '../../product-description/mobile-description';

export const MobileAccordion = ({ product, simpleProduct }) => {
    const t = usePhraseTranslater();
    const enableReturnLinks = false;
    return (
        <AccordionGroupState>
            {accordion => (
                <ul className={screenSwitch.showOnMobile}>
                    {enableReturnLinks && getProductAttribute(product, 'is_returnable') !== 'No' && (
                        <>
                            <AccordionItem
                                isActive={accordion.isActive('returns')}
                                toggle={accordion.toggleActive('returns')}
                                img={box}
                                text={t('Easy Returns')}
                            >
                                <ReturnsContent />
                            </AccordionItem>
                            <AccordionItem
                                isActive={accordion.isActive('guarantee')}
                                toggle={accordion.toggleActive('guarantee')}
                                img={approval}
                                text={t('100% Satisfaction Guaranteed')}
                            >
                                <GuaranteeContent />
                            </AccordionItem>
                        </>
                    )}
                    {enableReturnLinks && getProductAttribute(product, 'is_enable_rewards_popup') === 'Yes' &&
                        getDisplayRewards(product.rewardsData) && (
                        <AccordionItem
                            isActive={accordion.isActive('rewards')}
                            toggle={accordion.toggleActive('rewards')}
                            img={rewards}
                            text={t('EARN REWARDS CASH')}
                        >
                            <RewardsContent />
                        </AccordionItem>
                    )}
                    <AccordionItem
                        isActive={accordion.isActive('description')}
                        toggle={accordion.toggleActive('description')}
                        text={t('Product Description')}
                    >
                        <MobileDescription
                            product={product}
                            simpleProduct={simpleProduct}
                        />
                    </AccordionItem>
                </ul>
            )}
        </AccordionGroupState>
    );
};
