import React from 'react';

export const SectionTitle = ({ id = '', className = '', children }) => (
    <div
        id={id}
        className={className}
    >
        {children}
    </div>
);
