import React from 'react';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { Overlay } from 'ui/component/overlay';
import styles from './style.css';

export const PopoutItem = ({
    img,
    text,
    children,
}) => (
    <UseState initialState={false}>
        {([open, setOpen]) => (
            <>
                <li className={styles.popoutItem}>
                    <AccessibleButton
                        tag="span"
                        action={() => setOpen(true)}
                        className={styles.popoutItemLink}
                    >
                        {img && (
                            <img src={img} alt="" />
                        )}
                        <span className={styles.popoutTitle}>{text}</span>
                        <i className={`fa fa-circle-question ${styles.doubtIcon}`} />
                    </AccessibleButton>
                </li>
                <Overlay active={open} close={() => setOpen(false)}>
                    {children}
                </Overlay>
            </>
        )}
    </UseState>
);
