import { connect } from '@silkpwa/redux';

export const connectProductReviews = connect({
    using: ['ecommerceReviews'],
    mapDispatchToProps: ecommerceReviews => dispatch => ({
        resetReviewAfterVote:
            productId => dispatch(
                ecommerceReviews.actions.resetReviewAfterVote(productId),
            ),
    }),
});
