import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { StarRating } from 'ui/component/native-reviews/display/display/rating';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from 'ui/component/native-reviews/display/style.css';

export const ReviewCard = ({ review }) => {
    const t = usePhraseTranslater();
    const ratingValue = Array.isArray(review.rating_votes) && review.rating_votes.length > 0
        ? review.rating_votes[0].value
        : null;

    return (
        <div className={styles.reviewCard}>
            <div className={styles.textStars}>
                <div
                    className={classes(styles.snippetStars, styles.snippetStarsPng)}
                    role="img"
                    aria-label={ratingValue !== null ? t(`Rated ${ratingValue} out of 5 stars`) : t('Rating not available')}
                >
                    <div
                        aria-hidden="true"
                        className={styles.ratingStars}
                    >
                        <StarRating rating={ratingValue !== null ? ratingValue : 0} />
                    </div>
                    <div
                        aria-hidden="true"
                        className={styles.snippetRatingDecimal}
                    >
                        {ratingValue !== null ? ratingValue : ''}
                    </div>
                </div>
            </div>
            <p className={styles.reviewTitle}>{review.title}</p>
            <p className={styles.reviewContent}>{review.detail}</p>
        </div>
    );
};
