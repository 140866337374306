import React from 'react';
import badge from 'assets/images/satisfaction_returns/satisfaction-badge.png';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useDesignConfig } from '@silkpwa/module/design-config';
import styles from './style.css';

export const GuaranteeContent = () => {
    const t = usePhraseTranslater();
    const designConfig = useDesignConfig();
    return (
        <div className={styles.popout}>
            <img className={styles.badge} src={badge} alt="" />
            <div className={styles.title}>
                {t('At %1, Customer Satisfaction Is Our Top Priority', designConfig.pageTitle)}
            </div>
            <div className={styles.text}>
                {t(`We are happy to accept the return of any un-embroidered, unused and non-altered
item within 30 days of original purchase date.`)}
            </div>
        </div>
    );
};
