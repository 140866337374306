import React, {
    useState,
    useRef,
    ChangeEvent,
    FormEvent,
    FocusEvent,
} from 'react';
import { useMutation } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import ReCAPTCHA from 'react-google-recaptcha';
import Input from 'ui/component/input/input';
import Button from 'ui/component/button/button';
import ErrorMessage from 'ui/component/error-message/error-message';
import {
    CreateAnswerInput,
    CreateAnswerResponse,
} from 'ui/util/type-helper';
import { useContainerHook } from '@silkpwa/redux';
import { CREATE_ANSWER_MUTATION } from 'graphql/question-answer/mutations/createAnswer';
import {
    generateUniqueKey,
    isEmailValid,
    sanitizeInput,
    validationHandler,
} from 'ui/util/validator-helper';
import style from './styles.css';

interface AnswerFormProps {
    questionId: number;
    storeId: number;
    setActiveQuestionId: React.Dispatch<React.SetStateAction<number | null>>;
    buttonText?: string;
    customerId?: number;
    customerEmail?: string;
    customerNickname?: string;
    reCaptcWebsiteKey?: string;
}

const AnswerForm: React.FC<AnswerFormProps> = ({
    questionId,
    storeId,
    setActiveQuestionId,
    buttonText = 'Submit Answer',
    customerEmail,
    customerNickname,
    customerId,
    reCaptcWebsiteKey,
}) => {
    const [answer, setAnswer] = useState('');
    const [email, setEmail] = useState(customerEmail ?? '');
    const [nickname, setNickname] = useState(customerNickname ?? '');
    const [graphqlErrors, setGraphqlErrors] = useState<React.ReactNode>(null);

    const recaptchaRef = useRef<ReCAPTCHA>(null);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const enqueueNotification = useContainerHook<() => any>('useEnqueueNotification');

    const [
        createAnswer,
    ] = useMutation<CreateAnswerResponse, CreateAnswerInput>(CREATE_ANSWER_MUTATION);

    const [errors, setErrors] = useState({
        answer: {
            message: '',
        },
        email: {
            message: '',
        },
        nickname: {
            message: '',
        },
        submit: {
            message: '',
        },
    });

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        const sanitizedValue = sanitizeInput(value);

        if (name === 'answer') setAnswer(sanitizedValue);
        if (name === 'email') setEmail(sanitizedValue);
        if (name === 'nickname') setNickname(sanitizedValue);
    };

    const t = usePhraseTranslater();

    const setErrorData = (name: string, message: string) => {
        setErrors(prevState => ({
            ...prevState,
            [name]: {
                message,
            },
        }));
    };

    const handleOnBlur = (event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>) => {
        validationHandler(event, setErrorData);
    };

    const handleSubmit = async (recaptchaToken: string|null) => {
        if (answer.trim() && email.trim() && nickname.trim()) {
            const isValid = isEmailValid(email);
            if (!isValid) {
                setErrorData('email', t('Please enter a valid email'));
            } else {
                setErrorData('email', '');
                const requestBody: CreateAnswerInput = {
                    author: nickname,
                    answer,
                    email,
                    displayAt: storeId,
                    questionId,
                    customerId,
                };
                try {
                    const context = recaptchaToken ? {
                        headers: {
                            'X-ReCaptcha': recaptchaToken,
                        },
                    } : {};

                    const { errors, data } = await createAnswer({
                        context,
                        variables: requestBody,
                    });

                    if (data?.createAnswer?.answerId) {
                        setAnswer('');
                        setEmail('');
                        setNickname('');
                        setActiveQuestionId(null);
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                        enqueueNotification({
                            type: 'primary',
                            message: t('Your answer has been submitted successfully.'),
                            time: 5000,
                        });
                    }
                    if (errors && errors.length > 0) {
                        setGraphqlErrors(
                            <div className={style.gqlErrorCnt}>
                                {errors.map(
                                    (err, i) => (
                                        <ErrorMessage
                                            message={err.message.replace('$', '')}
                                            key={generateUniqueKey(i)}
                                        />
                                    ),
                                )}
                            </div>,
                        );
                    }
                } catch (e) {
                    setErrorData('submit', t('Something went wrong.'));
                }
            }
        } else {
            setErrorData('submit', t('Please fill in all fields.'));
        }
    };

    const triggerFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (recaptchaRef.current) {
            recaptchaRef.current.execute();
        } else {
            handleSubmit(null);
        }
    };

    return (
        <form onSubmit={triggerFormSubmit}>
            <Input
                id="answer"
                name="answer"
                type="textarea"
                label={t('Enter Your Reply')}
                placeholder={t('Add your reply here...')}
                value={answer}
                onChange={handleInputChange}
                onBlur={handleOnBlur}
                error={errors.answer}
                required
            />
            {!customerId && (
                <>
                    <Input
                        id="ans-email"
                        name="email"
                        type="email"
                        label={t('Enter Your Email')}
                        placeholder={t('Type your email here...')}
                        value={email}
                        onChange={handleInputChange}
                        onBlur={handleOnBlur}
                        error={errors.email}
                        required
                    />
                    <Input
                        id="nickname"
                        name="nickname"
                        type="text"
                        label={t('Enter Your Nickname')}
                        placeholder={t('Type your name here...')}
                        value={nickname}
                        onChange={handleInputChange}
                        onBlur={handleOnBlur}
                        error={errors.nickname}
                        required
                    />
                </>
            )}
            <div>
                {errors.submit.message && (
                    <div className={style.gqlErrorCnt}>
                        <ErrorMessage
                            message={errors.submit.message}
                        />
                    </div>
                )}
                {graphqlErrors}
            </div>
            <div className={style.btnCnt}>
                <Button
                    type="submit"
                    className={style.submitBtnCnt}
                >
                    <span className={style.submitBtn}>{buttonText}</span>
                </Button>
            </div>
            {reCaptcWebsiteKey && (
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={reCaptcWebsiteKey}
                    size="invisible"
                    isolated
                    onChange={(token) => {
                        handleSubmit(token);
                    }}
                />
            )}
        </form>
    );
};

export default AnswerForm;
