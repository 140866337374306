import React from 'react';
import sheetImage from 'assets/images/sheet.png';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { getProductSheet } from './get-product-sheet';
import styles from './style.css';

const ProductSheetLink = ({ sheet }) => {
    const t = usePhraseTranslater();
    return (
        <div>
            <a
                className={`${styles.productSheet} no-spa`}
                href={sheet}
                target="_blank"
                rel="noopener noreferrer"
            >
                <img className={styles['description-sheet-icon']} src={sheetImage} alt="" />
                <span className={styles['description-sheet']}>{t('PRODUCT SHEET')}</span>
            </a>
        </div>
    );
};

export const ProductSheet = ({ product }) => {
    const sheet = getProductSheet(product);
    if (sheet) {
        return (
            <React.Fragment>
                <div className={styles.splitLine} />
                <ProductSheetLink sheet={sheet} />
            </React.Fragment>
        );
    }
    return null;
};
