import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { getImage, getDescription } from './mapping';
import { getCare } from './get-care';
import styles from './style.css';

const CareItem = ({ item }) => (
    <li className={styles.CarelistItem}>
        <div><img src={getImage(item)} alt="" /></div>
        <span className={styles.careInfo}>{getDescription(item)}</span>
    </li>
);

const CareList = ({ careList }) => (
    <ul className={styles.careList}>
        {careList.map(item => (
            <CareItem key={item.code} item={item} />
        ))}
    </ul>
);

export const CareSection = ({ product }) => {
    const t = usePhraseTranslater();
    const careList = getCare(product);
    if (careList.length) {
        return (
            <div>
                <div className={styles['careWrap-label']}>{t('CARE')}</div>
                <CareList careList={careList} />
            </div>
        );
    }
    return null;
};
