import { gql, TypedDocumentNode } from '@apollo/client';

export const FLAG_REVIEW_MUTATION: TypedDocumentNode = gql`
    mutation FLAG_REVIEW_MUTATION($input: CreateFlagReviewInput!) {
        createFlagReview(input: $input) {
            review_id
            contact_email
            issue_type
            comment
            status
        }
    }
`;
