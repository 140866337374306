import React from 'react';
import style from './style.css';

export const SizingSliderSnapshot = ({ averageSizing }) => {
    const elements = [1, 2, 3, 4, 5];
    return (
        <>
            {elements.map(index => (
                <div
                    key={index}
                    className={`${index === averageSizing ? style.active : style.sliderNode}`}
                />
            ))}
        </>
    );
};
