import React from 'react';
import { CareSection } from './components/care-section';
import { TradeMarks } from './components/trademarks';
import { Bullets } from './components/bullets';
import { ProductSheet } from './components/product-sheet';
import { HowToVideo } from './components/how-to-video';
import { TextDescription } from './components/text-description';
import styles from './style.css';

export const MobileDescription = ({ product, simpleProduct }) => (
    <div className={styles['description-wrap']}>
        <div className={styles['description-main']}>
            <Bullets product={simpleProduct} />
            <HowToVideo product={simpleProduct} />
            <div className={styles.TradeMarksWrap}>
                <TradeMarks product={simpleProduct} />
                <TextDescription product={product} simpleDesc={simpleProduct} />
            </div>
            <CareSection product={simpleProduct} />
            <ProductSheet product={simpleProduct} />
        </div>
    </div>
);
