import React from 'react';

import styles from './styles.css';

interface ISectionTitleProps {
    id?: string;
    className?: string;
    children?: React.ReactNode;
}

export const SectionTitle: React.FC<ISectionTitleProps> = ({
    id = '',
    className = '',
    children,
}) => (
    <p
        id={id}
        className={`${styles.defaultTitleCnt} ${className}`}
    >
        {children}
    </p>
);
