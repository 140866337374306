import React from 'react';
import { UpsellProductsWidget } from 'ui/component/product/product-widget/upsell';

interface UpsellProductsProps {
    product: { id: number };
}

export const UpsellProducts = ({ product }: UpsellProductsProps) => (
    <UpsellProductsWidget productId={product.id} />
);
