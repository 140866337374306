import React from 'react';
import { MainColumn } from 'ui/component/main-column';
import screenSwitch from 'ui/styles/screen-switch.css';
import { connectUpsellProducts } from '@silkpwa/module/react-component/connect-upsell-products';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import styles from './style.css';
import { FullProductSlider as ProductSlider } from '../product-slider';

interface UpsellProductsWidgetImplProps {
    products: Array<object>;
}

const UpsellProductsWidgetImpl = ({ products }: UpsellProductsWidgetImplProps) => {
    const config = useConfig();
    if (products.length === 0 || !config.upsell_block_enable) return null;

    return (
        <MainColumn className={styles.upsellProductsMainColumn}>
            <div className={styles.title}>{config.upsell_block_title}</div>
            <div className={screenSwitch.showOnDesktop}>
                <ProductSlider items={products} show={5} location="upsellProducts" />
            </div>
            <div className={`${screenSwitch.showOnLargeMobile} ${screenSwitch.showOnMediumMobile}`}>
                <ProductSlider items={products} show={3} location="upsellProducts" />
            </div>
            <div className={screenSwitch.showOnSmallMobile}>
                <ProductSlider items={products} show={2} location="upsellProducts" />
            </div>
            <div className={screenSwitch.showOnVerySmallMobile}>
                <ProductSlider items={products} show={1} location="upsellProducts" />
            </div>
        </MainColumn>
    );
};

export const UpsellProductsWidget = connectUpsellProducts(UpsellProductsWidgetImpl);
