import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface RewardsItemProps {
    img?: string;
    text: string;
    isActive: boolean;
    toggle: () => void;
    tierText: string;
    className?: string;
}

export const RewardsItem = ({
    img,
    text,
    isActive,
    toggle,
    tierText,
    className,
}: RewardsItemProps) => (
    <AccessibleButton
        tag="div"
        action={toggle}
        className={className}
    >
        {img && (
            <img className={styles.accordionIcon} src={img} alt="" />
            )}
        <span className={styles.accordionTitle}>{text}</span>
        <span className={styles.accordionTitleLower}>{tierText}</span>
        <i
            className={classes(
               'material-icons',
               styles.arrow,
            )}
        >
            {isActive ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }
        </i>
    </AccessibleButton>
);
