import React from 'react';
import box from 'assets/images/satisfaction_returns/box.png';
import approval from 'assets/images/satisfaction_returns/approval.png';
import rewards from 'assets/images/marketing/rewards_small.png';
import { getProductAttribute } from 'ui/util/get-product-attribute';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { getDisplayRewards } from 'ui/util/get-display-rewards';
import { CreditKeyBanner } from 'ui/component/product-details/product-details-page/components/credit-key-banner';
import { classes } from '@silkpwa/module/util/classes';
import { PopoutItem } from '../../components/popout-item';
import { ReturnsContent } from '../../components/returns-content';
import { GuaranteeContent } from '../../components/guarantee-content';
import { RewardsContent } from '../../components/rewards-content';
import styles from './style.css';

interface SatisfactionPopoutsProps {
    product: any;
}
export const SatisfactionPopouts = (props: SatisfactionPopoutsProps) => {
    const t = usePhraseTranslater();
    const { product } = props;
    return (
        <>
            <CreditKeyBanner />
            <ul className={classes(styles.popoutItemWrapper)}>
                {getProductAttribute(product, 'is_returnable') !== 'No' && (
                    <>
                        <PopoutItem img={box} text={t('Easy Returns')}>
                            <ReturnsContent />
                        </PopoutItem>
                        <PopoutItem img={approval} text={t('100% Satisfaction Guaranteed')}>
                            <GuaranteeContent />
                        </PopoutItem>
                    </>
                )}
                {getProductAttribute(product, 'is_enable_rewards_popup') === 'Yes' &&
                    getDisplayRewards(product.rewardsData) && (
                        <PopoutItem img={rewards} text={t('Earn Rewards Cash')}>
                            <RewardsContent />
                        </PopoutItem>
                    )}
            </ul>
        </>
    );
};
