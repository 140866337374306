// eslint-disable-next-line max-len
import ChefworksLoyaltyProgramRewardsPointPdpDataInterface = Magento.Definitions.ChefworksLoyaltyProgramRewardsPointPdpDataInterface;

export function getDisplayRewards(
    pdpRewardsDetails: ChefworksLoyaltyProgramRewardsPointPdpDataInterface,
): boolean {
    let rewardsDisplayFlag = false;
    if (pdpRewardsDetails) {
        const {
            point_display: isPointDisplay,
            visible_on_pdp: isVisibleOnPlp,
        } = pdpRewardsDetails;
        if (isPointDisplay) {
            rewardsDisplayFlag = isVisibleOnPlp;
        }
    }
    return rewardsDisplayFlag;
}
