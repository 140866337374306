import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface AccordionItemProps {
    img?: string;
    text: string;
    isActive: boolean;
    toggle: () => void;
    children?: JSX.Element;
}

export const AccordionItem = ({
    img,
    text,
    isActive,
    toggle,
    children,
}: AccordionItemProps) => (
    <li className={styles.accordionItem}>
        <AccessibleButton
            tag="div"
            action={toggle}
            className={styles.accordionHeader}
        >
            {img && (
                <img className={styles.accordionIcon} src={img} alt="" />
            )}
            <span className={styles.accordionTitle}>{text}</span>
            <i
                className={classes(
                    'material-icons',
                    styles.arrow,
                )}
            >
                {isActive ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }
            </i>
        </AccessibleButton>
        {isActive && (
            <div className={styles.content}>
                {children}
            </div>
        )}
    </li>
);
