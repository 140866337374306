/* Makes creating the below mapping simpler */
/* eslint-disable camelcase */
import icons_100_cotton from 'assets/images/trademark/icons_100_cotton.png';
import icons_cool_vent from 'assets/images/trademark/icons_cool_vent.png';
import icons_lite from 'assets/images/trademark/icons_lite.png';
import icons_made_to_fade from 'assets/images/trademark/icons_made_to_fade.png';
import icons_sc_120 from 'assets/images/trademark/icons_sc_120.png';
import icons_stretch from 'assets/images/trademark/icons_stretch.png';
import icons_urban from 'assets/images/trademark/icons_urban.png';
import icons_contemporary from 'assets/images/trademark/icons_contemporary.png';
import icons_sustainable from 'assets/images/trademark/icons_sustainable.png';
import icons_flex_series from 'assets/images/trademark/icons_flex_series.png';
import icons_pima from 'assets/images/trademark/icons_pima.png';

/* eslint-enable */

export interface ITradeMarkItem {
    src: string;
    title: string;
}

const itemMapping: { [key: string]: ITradeMarkItem } = {
    icons_100_cotton: {
        src: icons_100_cotton,
        title: 'Made from 100% cotton · All natural fibers · Comfortable and breathable',
    },
    icons_cool_vent: {
        src: icons_cool_vent,
        title: 'Wicks heat & moisture away from skin · Allows cool air back through to body · Encourages evaporation · Cool & comfortable · Award-winning fabric technology',
    },
    icons_lite: {
        src: icons_lite,
        title: 'Fine twill fabric · Comfortable & lightweight · Available in a variety of colors',
    },
    icons_made_to_fade: {
        src: icons_made_to_fade,
        title: 'Special washes & treatments used to create this unique garment may cause a fade & change the color with wash & wear · Wash separately before wearing · Use warm iron to remove wrinkles on the backside of the garment',
    },
    icons_sc_120: {
        src: icons_sc_120,
        title: '100% Cotton · Longer garment lifespan · Pre-shrunk for accurate fit · Luxurious & refined feel for extreme comfort · Silky texture & appearance',
    },
    icons_stretch: {
        src: icons_stretch,
        title: 'Stretch-to-fit · Breathable fabric · Comfortable & lightweight · Available in a variety of colors',
    },
    icons_contemporary: {
        src: icons_contemporary,
        title: 'Contemporary collections',
    },
    icons_sustainable: {
        src: icons_sustainable,
        title: 'Sustainable collections',
    },
    icons_pima: {
        src: icons_pima,
        title: 'PIMA PREMIUM 0076',
    },
    icons_flex_series: {
        src: icons_flex_series,
        title: 'Flex Series',
    },
    icons_urban: {
        src: icons_urban,
        title: 'Street-inspired line of culinary apparel · Bold stripped patterns, lightweight dual-tone denims, unique fabric finishes · New twists on traditional classics · Cutting-edge · Affordable · High-quality',
    },
};

export function getItem(value: string): ITradeMarkItem {
    return itemMapping[value];
}
