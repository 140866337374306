import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import style from '../style.css';


const Verified: React.FC = () => (
    <p
        className={classes(
                style.rdReviewerType,
                style.rdInnerSideContentBlock,
                style.verifiedBuyer,
            )}
    >
        <span
            className={style.badgingIcon}
            aria-hidden="true"
        >
            <svg
                viewBox="0 0 19 19"
                focusable="false"
                aria-hidden="true"
            >
                <g
                    transform="translate(-76 -467) translate(76 467)"
                    fill="none"
                >
                    <circle
                        fill="#88D633"
                        cx="9.5"
                        cy="9.5"
                        r="9.5"
                    />
                    <g stroke="#FFF">
                        <path
                            d="M0 3l2.5 2.5M7.461.539l-4.58 4.58"
                            transform="translate(6 7)"
                        />
                    </g>
                </g>
            </svg>
        </span>
        <span className={style.rdBadgingText}>
        Verified Buyer
        </span>
    </p>
);

export default Verified;
