import React, { useState, useEffect } from 'react';
import thumbsUpIcon from 'assets/images/thumb-up.svg';
import thumbsUpIconVoted from 'assets/images/thumb-up-voted.svg';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { IAccountRepository } from '@silkpwa/magento/api/account-repository';
import { useContainer } from '@silkpwa/redux';
import { useAccount } from '@silkpwa/module/account';
import { FlagReviewPopup } from 'ui/component/native-reviews/display/display/flag-review-popup';
import FlagLogo from 'assets/images/flag-review.svg';
import style from 'ui/component/native-reviews/display/style.css';

export const ReviewHelpfulness = ({
    review, resetReview, fetchProductReviews, productId,
}) => {
    const [isFlagPopupOpen, setIsFlagPopupOpen] = useState(false);
    const [flagged, setFlagged] = useState(false);

    const openFlagPopup = () => {
        setIsFlagPopupOpen(true);
    };

    const closeFlagPopup = () => {
        setIsFlagPopupOpen(false);
    };

    const accountRepository: IAccountRepository = useContainer<IAccountRepository>(
        'accountRepository',
    );
    const { isLoggedIn } = useAccount();
    const [helpfulCount, setHelpfulCount] = useState(review.likes);
    const [notHelpfulCount, setNotHelpfulCount] = useState(review.dislikes);
    const [userVote, setUserVote] = useState(null);

    useEffect(() => {
        if (isLoggedIn) {
            const fetchVote = async () => {
                const vote = await accountRepository.isVotedByReviewId(review.review_id);
                if (vote !== null) {
                    setUserVote(vote ? 'helpful' : 'notHelpful');
                }
            };
            fetchVote();
        }
    }, [isLoggedIn, review.reviewId]);

    const handleVote = async (type) => {
        if (!isLoggedIn) {
            const referer = window.btoa(window.location.pathname);
            const redirect = '/customer/account/login/referer/';
            window.location.href = redirect + referer;
            return;
        }
        if (userVote === type) {
            return;
        }
        const voteType = type === 'helpful' ? 1 : 2;
        if (userVote && userVote !== type) {
            if (type === 'helpful') {
                setHelpfulCount(helpfulCount => helpfulCount + 1);
                setNotHelpfulCount(notHelpfulCount => notHelpfulCount - 1);
            } else {
                setHelpfulCount(helpfulCount => helpfulCount - 1);
                setNotHelpfulCount(notHelpfulCount => notHelpfulCount + 1);
            }
        } else if (!userVote) {
            if (type === 'helpful') {
                setHelpfulCount(helpfulCount => helpfulCount + 1);
            } else {
                setNotHelpfulCount(notHelpfulCount => notHelpfulCount + 1);
            }
        }
        await accountRepository.customerVote({ vote: voteType, reviewId: review.review_id });
        setUserVote(type);
        resetReview(productId);
        fetchProductReviews(productId);
    };

    const t = usePhraseTranslater();

    return (
        <div className={style.helpfulAction}>
            <div className={style.reviewFeedback}>
                <span className={style.reviewFeedbackText}>{t('WAS THIS REVIEW HELPFUL TO YOU?')}</span>
                <div className={style.voteButtons}>
                    <button
                        type="button"
                        className={classes(
                            style.helpfulnessButton,
                            { [style.selectedVote]: userVote === 'helpful' },
                        )}
                        onClick={() => handleVote('helpful')}
                    >
                        <img src={userVote === 'helpful' ? thumbsUpIconVoted : thumbsUpIcon} alt={t('Like')} />
                        <span
                            className={classes(
                                style.voteCount,
                                { [style.voteCountSelected]: userVote === 'helpful' },
                            )}
                        >
                            {helpfulCount}
                        </span>
                    </button>
                    <button
                        type="button"
                        className={classes(
                            style.helpfulnessButtonDislike,
                            { [style.selectedVote]: userVote === 'notHelpful' },
                        )}
                        onClick={() => handleVote('notHelpful')}
                    >
                        <img src={userVote === 'notHelpful' ? thumbsUpIconVoted : thumbsUpIcon} alt={t('Dislike')} />
                        <span className={classes(
                            style.voteCount,
                            { [style.voteCountSelected]: userVote === 'notHelpful' },
                        )}
                        >
                            {notHelpfulCount}
                        </span>
                    </button>
                </div>
                <div className={style.flagReviewContainer}>
                    {!flagged ? (
                        <button
                            type="button"
                            onClick={openFlagPopup}
                            className={style.flagReviewBtn}
                        >
                            {t('Flag this review')}
                        </button>
                    ) : (
                        <div className={style.flaggedMessage}>
                            <span className={style.flagIcon}>
                                <img src={FlagLogo} alt={t('Flag Review')} />
                            </span>
                            <span>{t('You Flagged This Review')}</span>
                        </div>
                    )}
                </div>
                <FlagReviewPopup
                    isOpen={isFlagPopupOpen}
                    onClose={closeFlagPopup}
                    onSuccess={() => setFlagged(true)}
                    reviewId={review.review_id}
                />
            </div>
        </div>
    );
};
