type Attribute = {
    code: string;
    value: string|null;
};
type Product = {
    attributes: Array<Attribute>;
};

const sortPositions = {
    care_wash: 0,
    care_dry_clean: 1,
    care_bleach: 2,
    care_drying: 3,
    care_iron: 4,
};

const sorter = (a: Attribute, b: Attribute) => {
    const x = sortPositions[a.code];
    const y = sortPositions[b.code];
    return x - y;
};

export const getCare = (product: Product) => product.attributes
    .filter(x => x.code.indexOf('care_') === 0)
    .filter(x => x.value !== null && x.value.trim() !== '')
    .sort(sorter);
