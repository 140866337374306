import React from 'react';
import { ReviewIssues } from 'ui/component/native-reviews/display/display/flag-issue-options';
import style from 'ui/component/native-reviews/display/style.css';

export const FlaggingFormGroup = ({ issue, setIssue, t }) => {
    const issueOptionsGroup1 = [
        { value: ReviewIssues.PROFANITY, label: t('Profanity') },
        { value: ReviewIssues.WRONG_PRODUCT, label: t('Wrong Product') },
        { value: ReviewIssues.SPAM, label: t('Spam') },
        { value: ReviewIssues.DUPLICATE, label: t('Duplicate') },
    ];

    const issueOptionsGroup2 = [
        { value: ReviewIssues.COPYRIGHT_VIOLATION, label: t('Copyright Violation') },
        { value: ReviewIssues.NOT_A_PRODUCT_REVIEW, label: t('Not a Product Review') },
        { value: ReviewIssues.CUSTOMER_IMAGE, label: t('Customer Image') },
        { value: ReviewIssues.OTHER, label: t('Other') },
    ];

    return (
        <>
            <div className={style.flaggingFormGroup}>
                {issueOptionsGroup1.map(option => (
                    <div className={style.flaggingRadio} key={option.value}>
                        <label>
                            <input
                                className={style.flagInput}
                                type="radio"
                                value={option.value}
                                checked={issue === option.value}
                                onChange={e => setIssue(parseInt(e.target.value, 10))}
                            />
                            <span className={style.flagRadioOption}>{option.label}</span>
                        </label>
                    </div>
                ))}
            </div>
            <div className={style.flaggingFormGroup}>
                {issueOptionsGroup2.map(option => (
                    <div className={style.flaggingRadio} key={option.value}>
                        <label>
                            <input
                                className={style.flagInput}
                                type="radio"
                                value={option.value}
                                checked={issue === option.value}
                                onChange={e => setIssue(parseInt(e.target.value, 10))}
                            />
                            <span className={style.flagRadioOption}>{option.label}</span>
                        </label>
                    </div>
                ))}
            </div>
        </>
    );
};
