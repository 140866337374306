export const ReviewIssues = {
    PROFANITY: 1,
    WRONG_PRODUCT: 2,
    SPAM: 3,
    DUPLICATE: 4,
    COPYRIGHT_VIOLATION: 5,
    NOT_A_PRODUCT_REVIEW: 6,
    CUSTOMER_IMAGE: 7,
    OTHER: 8,
};
