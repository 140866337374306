import React, { useState, useEffect } from 'react';

export const TimeDifference = ({ createdAt }) => {
    const [timeDiff, setTimeDiff] = useState('');

    useEffect(() => {
        const currentTime = new Date();
        const pastTime = new Date(createdAt);

        const yearsDiff = currentTime.getFullYear() - pastTime.getFullYear();
        const monthsDiff = currentTime.getMonth() - pastTime.getMonth();
        const daysDiff = currentTime.getDate() - pastTime.getDate();
        const hoursDiff = currentTime.getHours() - pastTime.getHours();
        const minutesDiff = currentTime.getMinutes() - pastTime.getMinutes();
        const secondsDiff = currentTime.getSeconds() - pastTime.getSeconds();

        const timeDifference = [];

        if (yearsDiff > 0) {
            timeDifference.push(`${yearsDiff} year${yearsDiff > 1 ? 's' : ''}`);
        } else if (monthsDiff > 0) {
            timeDifference.push(`${monthsDiff} month${monthsDiff > 1 ? 's' : ''}`);
        } else if (daysDiff > 0) {
            timeDifference.push(`${daysDiff} day${daysDiff > 1 ? 's' : ''}`);
        } else if (hoursDiff > 0) {
            timeDifference.push(`${hoursDiff} hour${hoursDiff > 1 ? 's' : ''}`);
        } else if (minutesDiff > 0) {
            timeDifference.push(`${minutesDiff} minute${minutesDiff > 1 ? 's' : ''}`);
        } else if (secondsDiff > 0) {
            timeDifference.push(`${secondsDiff} second${secondsDiff > 1 ? 's' : ''}`);
        }

        setTimeDiff(timeDifference.join(', '));
    }, [createdAt]);

    return (
        <>
            &nbsp;
            {timeDiff}
            &nbsp;ago
        </>
    );
};
