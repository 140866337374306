import React, { useMemo, useCallback } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import Button from 'ui/component/button/button';
import { classes } from '@silkpwa/module/util/classes';
import style from './styles.css';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    className?: string;
    scrollToElementId?: string;
}

const Pagination: React.FC<PaginationProps> = ({
    currentPage,
    totalPages,
    onPageChange,
    className,
    scrollToElementId,
}) => {
    const t = usePhraseTranslater();
    const scrollToElement = (elementId: string) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleClick = useCallback(
        (page: number) => {
            if (page >= 1 && page <= totalPages) {
                onPageChange(page);
                if (scrollToElementId) {
                    scrollToElement(scrollToElementId);
                }
            }
        },
        [onPageChange, totalPages, scrollToElementId],
    );

    const renderPageNumbers = useMemo(() => {
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, startPage + 2);
        const pages = [];
        for (let i = startPage; i <= endPage; i += 1) {
            pages.push(
                <Button
                    key={i}
                    onClick={() => handleClick(i)}
                    className={classes(
                        style.pgnBtnCss,
                        'page-item',
                        {
                            [style.pageItemActive]: currentPage === i,
                        },
                    )}
                >
                    {i}
                </Button>,
            );
        }

        return pages;
    }, [currentPage, totalPages, handleClick]);

    return (
        <div className={`pagination ${style.paginationCnt} ${className}`}>
            {currentPage > 1 && (
                <>
                    <Button
                        onClick={() => handleClick(1)}
                        className={style.pgnBtnCss}
                    >
                        {t('First')}
                    </Button>
                    <Button
                        onClick={() => handleClick(currentPage - 1)}
                        className={style.pgnBtnCss}
                    >
                        {t('Previous')}
                    </Button>
                </>
            )}
            {renderPageNumbers}
            {currentPage < totalPages && (
                <>
                    <Button
                        onClick={() => handleClick(currentPage + 1)}
                        className={style.pgnBtnCss}
                    >
                        {t('Next')}
                    </Button>
                    <Button
                        onClick={() => handleClick(totalPages)}
                        className={style.pgnBtnCss}
                    >
                        {t('Last')}
                    </Button>
                </>
            )}
        </div>
    );
};

export default Pagination;
