import React from 'react';
import { MainColumn } from 'ui/component/main-column';
import screenSwitch from 'ui/styles/screen-switch.css';
import { connectRelatedProducts } from '@silkpwa/module/react-component/connect-related-products';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import styles from './style.css';
import { FullProductSlider as ProductSlider } from '../product-slider';

interface RelatedProductsWidgetImplProps {
    products: Array<object>;
}

const RelatedProductsWidgetImpl = ({ products }: RelatedProductsWidgetImplProps) => {
    const config = useConfig();
    if (products.length === 0 || !config.related_block_enable) return null;

    return (
        <MainColumn className={styles.relatedProductsMainColumn}>
            <div className={styles.alsoBoughtWithTitle}>{config.related_block_title}</div>
            <div className={screenSwitch.showOnDesktop}>
                <ProductSlider items={products} show={5} location="relatedProducts" />
            </div>
            <div className={`${screenSwitch.showOnLargeMobile} ${screenSwitch.showOnMediumMobile}`}>
                <ProductSlider items={products} show={3} location="relatedProducts" />
            </div>
            <div className={screenSwitch.showOnSmallMobile}>
                <ProductSlider items={products} show={2} location="relatedProducts" />
            </div>
            <div className={screenSwitch.showOnVerySmallMobile}>
                <ProductSlider items={products} show={1} location="relatedProducts" />
            </div>
        </MainColumn>
    );
};

export const RelatedProductsWidget = connectRelatedProducts(RelatedProductsWidgetImpl);
