import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { timeAgo } from 'ui/util/validator-helper';
import verifiedUser from 'assets/images/verified-user.svg';

import styles from './styles.css';

interface AnswerTextProps {
    text: string;
    author: string;
    createdAt: string;
    className?: string;
    isVerifiedUser?: boolean;
    displayAuthor?: boolean;
    children?: React.ReactNode;
}

const AnswerText: React.FC<AnswerTextProps> = ({
    text,
    author,
    createdAt,
    className,
    isVerifiedUser = false,
    displayAuthor = false,
    children,
}) => {
    const t = usePhraseTranslater();
    return (
        <div className={`${styles.answerCntCss} ${className}`}>
            <div className={`${styles.ansAuthCnt} answer-authorCnt`}>
                <div>
                    {isVerifiedUser ? (
                        <div className={styles.verifiedCnt}>
                            <span aria-hidden="true" className="pr-qa-display-answer-icon">
                                <span className="pr-badging-icon" aria-hidden="true">
                                    <img src={verifiedUser} alt="Verified user" />
                                </span>
                            </span>
                            <span
                                className="pr-answer-by-expert pr-qa-display-answer-by pr-subscript"
                            >
                                <span>{t('Verified Reply')}</span>
                                <span>
                                    <span> - </span>
                                    <span>{author}</span>
                                </span>
                            </span>
                        </div>
                    ) : (
                        <div className={styles.verifiedCnt}>
                            <span
                                className="pr-answer-by-expert pr-qa-display-answer-by pr-subscript"
                            >
                                <span>{t('Reply')}</span>
                                <span>
                                    <span> - </span>
                                    <span>{author}</span>
                                </span>
                            </span>
                        </div>
                    )}
                    <span className={styles.qaAnsTextCss}>{text}</span>
                </div>
                {displayAuthor && (
                    <span>
                        {author}
                        <b className={styles.postedTimeCss}>{timeAgo(createdAt)}</b>
                    </span>
                )}
            </div>

            {children}
        </div>
    );
};

export default AnswerText;
