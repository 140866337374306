import React from 'react';
import { getBullets } from './get-bullets';
import styles from './style.css';

const BulletList = ({ features, attributes }) => (
    <ul className={styles.bullets}>
        {features.map(item => (item ? (
            <li key={item}>{item}</li>
        ) : ''))}
        <li key="fabric_blend">{attributes.fabric_blend}</li>
        <li key="fabric_weight">{attributes.fabric_weight}</li>
        <li key="alt_colors">{attributes.alt_colors}</li>
        <li key="size">{attributes.size}</li>
    </ul>
);

export const Bullets = ({ product }) => {
    const { features, attributes } = getBullets(product);
    return (
        <BulletList features={features} attributes={attributes} />
    );
};
