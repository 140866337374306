import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccordionGroupState } from '@silkpwa/module/react-component/accordion-group-state';
import free from 'assets/images/marketing/free.png';
import paid from 'assets/images/marketing/paid.png';
import { Container } from 'ui/component/container';
import rewardsSmall from 'assets/images/marketing/rewards_small.png';
import { RewardsAccordionItem } from 'ui/component/product-details/product-details-page/components/rewards-accordion-item';
import { BronzeContent } from 'ui/component/product-details/product-details-page/components/rewards-content/tiers/bronze-content';
import { SilverContent } from 'ui/component/product-details/product-details-page/components/rewards-content/tiers/silver-content';
import { GoldContent } from 'ui/component/product-details/product-details-page/components/rewards-content/tiers/gold-content';
import { RubyContent } from 'ui/component/product-details/product-details-page/components/rewards-content/tiers/ruby-content';
import { DiamondContent } from 'ui/component/product-details/product-details-page/components/rewards-content/tiers/diamond-content';

import styles from './style.css';

export const RewardsContent = () => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.main}>
            <div className={styles.popout}>
                <div className={styles.popoutBody}>
                    <div className={styles.title}>
                        <img src={rewardsSmall} alt="" />
                        {t('EARN REWARDS CASH')}
                    </div>
                    <div className={styles.header}>
                        <Container id="header-loyalty-rewards-detail" />
                    </div>
                    <AccordionGroupState>
                        {accordion => (
                            <ul className={styles.rewardsAccordionItem}>
                                <div className={styles.bronze}>
                                    <RewardsAccordionItem
                                        isActive={accordion.isActive('bronze')}
                                        toggle={accordion.toggleActive('bronze')}
                                        img={free}
                                        type="bronze"
                                        text={t('Bronze')}
                                    >
                                        <BronzeContent />
                                    </RewardsAccordionItem>
                                </div>
                                <div className={styles.silver}>
                                    <RewardsAccordionItem
                                        isActive={accordion.isActive('silver')}
                                        toggle={accordion.toggleActive('silver')}
                                        img={free}
                                        type="silver"
                                        text={t('Silver')}
                                    >
                                        <SilverContent />
                                    </RewardsAccordionItem>
                                </div>
                                <div className={styles.gold}>
                                    <RewardsAccordionItem
                                        isActive={accordion.isActive('gold')}
                                        toggle={accordion.toggleActive('gold')}
                                        img={free}
                                        type="gold"
                                        text={t('Gold')}
                                    >
                                        <GoldContent />
                                    </RewardsAccordionItem>
                                </div>
                                <div className={styles.ruby}>
                                    <RewardsAccordionItem
                                        isActive={accordion.isActive('ruby')}
                                        toggle={accordion.toggleActive('ruby')}
                                        img={paid}
                                        type="ruby"
                                        text={t('Ruby')}
                                    >
                                        <RubyContent />
                                    </RewardsAccordionItem>
                                </div>
                                <div className={styles.diamond}>
                                    <RewardsAccordionItem
                                        isActive={accordion.isActive('diamond')}
                                        toggle={accordion.toggleActive('diamond')}
                                        img={paid}
                                        type="diamond"
                                        text={t('Diamond')}
                                    >
                                        <DiamondContent />
                                    </RewardsAccordionItem>
                                </div>
                            </ul>
                        )}
                    </AccordionGroupState>
                </div>
            </div>
            <div className={styles.popoutFooter}>
                <Container id="footer-loyalty-rewards-detail" />
            </div>
        </div>
    );
};
